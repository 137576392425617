// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    readData,
    readManyData,
    readDataList
} from '../../helper/crudHandler'


export const readCategory = async () => {
    return await readManyData('/category/all')
}

export const readCategoryById = async categoryId => {
    return await readData(`/category/id/${categoryId}`)
}

export const readProductsByCategoryId = async (categoryId, option) => {
    return await readDataList(`/category/product/category-id/${categoryId}`, option)
}

export const readProductsByCategorySlug = async (slug, option) => {
    return await readDataList(`/category/product/category-slug/${slug}`, option)
}
