// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import HeaderTop from './block/HeaderTop'
import HeaderLeft from './block/HeaderLeft'
import HeaderRight from './block/HeaderRight'
import HeaderScroll from './HeaderScroll'
import { Link } from 'react-router-dom'
import LogoWhite from './logo_white.svg'
import LogoBlack from './logo_black.svg'
import './style.css'


const HeaderPC = props => {

    const [ isScrolled, setScrolled ] = useState(false)
    const [ barWidth, setBarWidth ] = useState(0)

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)

        return() => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [])


    const scrollHandler = () => {
        const winScroll = window.pageYOffset
        setScrolled(winScroll > 600 ? true : false)
        const scroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
        const scrolled = (scroll / height) * 100
        setBarWidth(scrolled + '%')
    }

    const containerStyle = {
        position: props.isHome ? 'absolute' : 'relative',
        background: props.isHome ? 'rgba(0, 0, 0, 0.4)' : '#FFFFFF',
        boxShadow: props.isHome ? 'none' : '0 0 5px rgba(0, 0, 0, 0.3)'
    }

    return (
        <React.Fragment>
            <HeaderTop />
            <div id='HeaderContainer' style={containerStyle}>
                <HeaderLeft isHome={props.isHome} />
                <div className='LogoContainer'>
                    <Link to='/'>
                        <img src={props.isHome ? LogoWhite : LogoBlack} alt='mustom' />
                    </Link>
                </div>
                <HeaderRight isHome={props.isHome} />
            </div>
            <HeaderScroll isOpen={isScrolled} barWidth={barWidth} />
        </React.Fragment>        
    )
}


export default HeaderPC