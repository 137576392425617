// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { signIn } from '../../api/auth/signIn'
import LoginContext from '../../context/LoginContext'
import showNotice from '../../helper/noticeDialog'


const SignIn = props => {

    const user = useContext(LoginContext)
    const navigate = useNavigate()
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')

    const keyPressHandler = key => {
        if (key === 'Enter') { submitInfo() }
    }

    const submitInfo = async () => {
        if (!email || !password) {
            showNotice('Please enter require field!')
            return
        } 

        const dataToSend = {
            userName: email,
            password: password
        }

        const signInRequest = await signIn(dataToSend)

        switch (signInRequest.result) {
            case 'success':
                showNotice('Logged in successfully! Welcome back!')
                navigate(-1)
                user.updateStatus()
                break
            case 'account-not-found':
                showNotice('Could not find any account. Please check your email!')
                break
            case 'password-mismatch':
                showNotice('Password is not matched!')
                break
            case 'account-not-verified':
                showNotice('You need to verify your accout through email confirmation!')
                break
            case 'account-not-active':
                showNotice('This account is inactive state.')
                break
            case 'too-many-failure':
                showNotice(`Too many failed login attempts. Please visit 'forget password' page to reset your password.`)
                break
            default:
                showNotice('Something went wrong! Please try again!')
                break
        }
    }
    
    return (
        <React.Fragment>
            <div className='HalfContainer'>
                <div className='SignLeft'>
                    <p>Your Email</p>
                    <input
                        name='email'
                        type='email'
                        placeholder='e.g. jone-doe@mustom.com'
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <p>Password</p>
                    <input
                        name='password'
                        type='password'
                        value={password}
                        onKeyPress={event => keyPressHandler(event.key)}
                        onChange={event => setPassword(event.target.value)}
                    />
                    <span className='FullWidthBtn' style={{margin : '20px 0'}} onClick={submitInfo}>Sign In</span>
                    <p style={{textAlign: 'right', cursor: 'pointer'}} onClick={() => props.changePage('forget-password')}>Forget Password?</p>
                </div>
            </div>
            <div className='HalfContainer' style={{borderLeft: '1px solid #E4E4E4'}}>
                <div className='SignRight'>
                    <h5 style={{marginBottom : '20px'}}>Not Registered Yet?</h5>
                    <p style={{marginBottom : '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam, quam at eleifend eleifend, tellus ex faucibus elit, quis pharetra sapien purus eu ex. Pellentesque rhoncus molestie elit ut elementum.</p>
                    <p style={{marginBottom : '20px'}}>Nulla semper malesuada urna, ac imperdiet leo tincidunt sed. Aliquam vulputate neque at dui ullamcorper, sit amet tristique purus volutpat.</p>
                    <p style={{marginBottom : '30px'}}>Quisque gravida elit a congue malesuada. Ut malesuada convallis turpis nec auctor. Pellentesque molestie magna lectus, interdum faucibus eros egestas et. In enim massa, imperdiet pharetra nunc varius, posuere rutrum urna.</p>                    
                <span className='FullWidthBtn' onClick={() => props.changePage('sign-up')}>Sign Up for New Account</span>
            </div>
        </div>
        </React.Fragment>
        
    )
}   

export default SignIn