// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import dropin from 'braintree-web-drop-in'
import { requestToken, sendNonce } from '../../api/braintree/braintree'
import './style.css'


const BrainTree = props => {

    const [ braintreeInstance, setBraintreeInstance ] = useState(undefined)

    useEffect(() => {
        proceedPayment(props.orderId)
    }, [])


    const proceedPayment = async (orderId) => {

        if (!orderId) {
            props.paymentCallback(false)
            return
        }

        const tokenRequest = await requestToken(orderId)
        
        if (tokenRequest.result !== 'success' || !tokenRequest.info) {
            props.paymentCallback(false)
            return
        }

        const initializeBraintree = () => dropin.create({
            authorization: tokenRequest.info,
            container: '#dropin-container',
            locale: 'en_US'
        }, (error, instance) => {
            if (error) {
                props.paymentCallback(false)
            } else {
                setBraintreeInstance(instance)
            }
        })

        if (braintreeInstance) {
            braintreeInstance
            .teardown()
            .then(() => {
                initializeBraintree()
            })
        } else {
            initializeBraintree()
        }
    }

    const completePayment = async () => {

        if (!braintreeInstance) {
            props.paymentCallback(false)
            return
        }

        braintreeInstance.requestPaymentMethod(async (error, payload) => {
            if (error) {
                props.paymentCallback(false)
            } else {
                const paymentMethodNonce = payload.nonce
                const confirmPayment = await sendNonce(paymentMethodNonce, props.orderId)
                if (confirmPayment.result !== 'success') {
                    props.paymentCallback(false)
                    return
                }

                props.paymentCallback(true)
            }
        })
    }

    return (
        <div className='NightWrapper'>
            <div id='BrainTree'>
                <p
                    id='BraintreeLoading'
                    style={{display: braintreeInstance ? 'none' : 'block'}}
                >Loading...</p>
                <div
                    id='dropin-container'
                    style={{minHeight: '280px'}}
                ></div>
                <p
                    className='RegSmallBtn'
                    onClick={() => completePayment()}
                >Pay
                </p>
            </div>
        </div>
    )
}


export default BrainTree



