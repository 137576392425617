// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { updateOrderById } from '../../api/order/order'
import showNotice from '../../helper/noticeDialog'


const EmailInput = props => {

    const [ email, setEmail ] = useState('')
    const [ enableInput, setInput ] = useState(false)

    useEffect(() => {

        if (props.orderId && props.email) {
            setEmail(props.email)
        }

        if (!props.email) {
            setInput(true)
        }

    }, [ props.orderId ])

    const updateEmail = async () => {

        if (!enableInput || !props.orderId || !email) { return }

        const updateRequest = await updateOrderById(props.orderId, {
            email: email
        })

        if (updateRequest.result !== 'success') {
            showNotice('Failed to update email address. Please try again.')
        } else {
            props.updateCallback()
        }

        setInput(false)
        setEmail('')
    }

    const onChangeHandler = event => {
        if (!enableInput) {
            return
        }

        setEmail(event.target.value)
    }

    const EmailBlock = () => {
        return (
            <div id='CheckoutEmail'>
                <p>Your email address</p>
                <span>{props.email}</span>
                <span
                    style={{display: props.customerId ? 'none' : 'inline-block'}}
                    onClick={() => setInput(true)}
                >Change</span>
            </div>
        )
    }

    return (
        <div>
            <span className='CheckoutMenu'>Email Address</span>
            <div className='CheckoutMenuContent'>
            {props.email ? <EmailBlock /> : null}
            <div className='CheckoutInputWrapper' style={{display: props.customerId ? 'none' : 'flex'}}>
                <input
                    type='text'
                    id='email'
                    placeholder='Enter Your Email'
                    disabled={enableInput ? false : true}
                    value={email}
                    onChange={onChangeHandler}
                />
                <span
                    className='InputButton'
                    style={{opacity: enableInput ? 1 : 0.5}}
                    onClick={updateEmail}
                >Submit</span>
            </div>
            <p
                className='GuideText'
                style={{margin: '10px 0 50px 0', display: props.customerId ? 'none' : 'block'}}
            >Enter valid email address. Emails include order confirmation email will be sent to this email address.</p>
            </div>
        </div>
    )
}


export default EmailInput



