// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect, useCallback } from 'react'
import { readRateByProductId } from '../../../api/review/rate'


const Rate = props => {

    const [ rate, setRate ] = useState('')
    const [ count, setCount ] = useState('')

    useEffect(() => {
        getRate(props.productId)
    }, [ props.productId ])

    const getRate = async productId => {
    
        if (!productId) {
            return
        }

        const fetchRate = await readRateByProductId(productId)

        if (fetchRate.result !== 'success' || !fetchRate.info) {
            setRate(null)
            return
        }

        setRate(fetchRate.info?.averageRate || null)
        setCount(fetchRate.info?.reviewCount || null)
    }

    const NoRate = () => {
        return (
            <span>Be the first review</span>
        )
    }

    const StarRate = () => {
        return (
            <div className='RateContainer'>
                <span>&#128970;</span>
                <span>{rate} out of 5 ({count})</span>
            </div>
        )
    }

    return (
        <div id='ProductStar'>
            {!count || !rate ? <NoRate /> : <StarRate />}
        </div>
    )
}


export default Rate
