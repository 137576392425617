// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import showNotice from './noticeDialog'


export const getConfig = name => {

    try {
        const config = localStorage.getItem('coreConfig')
        const configParsed = JSON.parse(config)
    
        if (!configParsed || configParsed[name] === undefined) { return }
    
        return configParsed[name]

    } catch (error) {

        showNotice('Something went wrong. Please refresh this page, and try again.')

    }
}

  
