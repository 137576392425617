// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    createData,
    readData,
    updateData,
    deleteManyData
} from '../../helper/crudHandler'


export const addItemToCart = async data => {

    const cartKey = localStorage.getItem('cart_key') || 'new'
    const dataToSend = data
    Object.assign(dataToSend, { cartKey: cartKey })

    const addRequest = await createData('/cart/add', dataToSend)

    if (addRequest.result === 'success' && addRequest?.info?.key) {
        localStorage.setItem('cart_key', addRequest.info.key)
    }

    return addRequest
}

// RE-Order feature
export const addPreviosOrderItemToCart = async orderId => {

    const cartKey = localStorage.getItem('cart_key') || 'new'
    const dataToSend = {
        cartKey: cartKey,
        prviousOrderId: orderId
    }

    const addRequest = await createData('/cart/reorder', dataToSend)

    if (addRequest.result === 'success' && addRequest?.info?.key) {
        localStorage.setItem('cart_key', addRequest.info.key)
    }

    return addRequest
}

// Get Cart
export const getCart = async cartKey => {
    const cartInfo = await readData(`/cart/key/${cartKey}`)

    if (['key-not-exist', 'invalid-cart-data'].includes(cartInfo.info)) {
        localStorage.removeItem('cart_key')
    }

    return cartInfo
}

// Increase, or decrease cart item
export const updateCartItem = async (itemId, qty) => {
    const dataToSend = { qty: qty }
    return await updateData(`/cart-item/${itemId}`, dataToSend)
}

// Delete Cart Item
export const deleteCartItem = async itemIds => {
    return await deleteManyData(`/cart-item`, itemIds)
}



export const checkItem = () => {
    // Status, 재고와 가격 확인
}


// Calculate qty beside cart icon
export const readMiniCart = async () => {
    const cartKey = localStorage.getItem('cart_key')
    if (!cartKey) { return }
    return await readData(`/cart/mini-cart/key/${cartKey}`)
}
