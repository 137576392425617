// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    readData,
    readManyData
} from '../../helper/crudHandler'


// export const readProductByCategoryId = async categoryId => {
//     return await readData(`/product-list/category-id/${categoryId}`)
// }

export const readProductBySlug = async slug => {
    return await readData(`/product/slug/${slug}`)
}

export const readProductById = async productId => {
    return await readData(`/product/id/${productId}`)
}

export const readRedisProductById = async productId => {
    return await readData(`/product-list/id/${productId}`)
}

export const readPriceByProductId = async productId => {
    return await readData(`/price/product-id/${productId}`)
}

export const readStockByProductId = async productId => {
    return await readData(`/inventory/product-id/${productId}`)
}

export const readOptionByProductId = async productId => {
    return await readManyData(`/product-option/product-id/${productId}`)
}



