// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readAddressByCustomerId } from '../../../api/customer/address'
import { updateOrderAddressByOrderId } from '../../../api/order/order'
import { createAddress } from '../../../api/customer/address'
import showInnerNotice from '../../../helper/innerNoticeDialog'
import Country from '../../misc/Country'
import Province from '../../misc/Province'


const ShippingAddressInputBox = props => {

    const [ address, setAddress ] = useState({})
    const [ addressBook, setAddressBook ] = useState([])
    const [ shouldRegister, toggleRegister ] = useState(false)
    const [ shouldSaveDefaultBillTo, toggleDefaultBillTo ] = useState(false)
    const [ shouldSaveDefaultShipTo, toggleDefaultShipTo ] = useState(false)

    useEffect(() => {
        getAddressBook(props.customerId)
    }, [props])

    const getAddressBook = async customerId => {

        if (!customerId) { return }

        const fetchAddresses = await readAddressByCustomerId(customerId)

        if (fetchAddresses.result !== 'success' || !fetchAddresses.length) {
            setAddressBook([])
        }

        setAddressBook(fetchAddresses)
    }
    
    const changeAddress = event => {
        const newAddress = { ...address, [event.target.id] : event.target.value }
        setAddress(newAddress)
    }

    const changeCountry = (code, name) => {
        const newAddress = { ...address, country: name, countryCode: code }
        setAddress(newAddress)
    }

    const changeProvince = (code, name) => {
        const newAddress = { ...address, province: name, provinceCode: code }
        setAddress(newAddress)
    }

    const sendAddressInBook = async index => {

        const addressInBook = addressBook[index]
        const dataToSend = {
            type: 'billing',
            firstName: addressInBook.firstName,
            lastName: addressInBook.lastName,
            telephone1: addressInBook.telephone1,
            address1: addressInBook.address1,
            address2: addressInBook.address2,
            city: addressInBook.city,
            zip: addressInBook.zip,
            province: addressInBook.province,
            provinceCode: addressInBook.provinceCode,
            country: addressInBook.country,
            countryCode: addressInBook.countryCode, 
        }

        const updateRequest = await updateOrderAddressByOrderId(props.orderId, dataToSend)

        if (updateRequest.result !== 'success') {
            showInnerNotice('Failed to update address. Please try again later.')
            return
        }

        props.updateCallback()
        props.closeCallback()
    }
    
    const createNewAddress = async () => {

        const dataToSend = address

        if (props.customerId) {
            Object.assign(dataToSend, { customerId: props.customerId })
        }

        const dataWithType = Object.assign({}, { ...dataToSend, type: 'billing' })
        const updateOrderAddressRequest = await updateOrderAddressByOrderId(props.orderId, dataWithType)
        if (updateOrderAddressRequest.result !== 'success') {
            showInnerNotice('Failed to update billing address.')
            return
        }
        
        // Create Address Book record
        if (shouldSaveDefaultBillTo) {
            Object.assign(dataToSend, { isDefaultShipTo: true })
        }

        if (shouldSaveDefaultShipTo) {
            Object.assign(dataToSend, { isDefaultShipTo: true })
        }

        if (shouldRegister) {
            createAddress(dataToSend)
        }
        
        await showInnerNotice('Your address has been saved successfully.')
        props.updateCallback()
        props.closeCallback()
    }

    const defaultLabel = address => {
        const isDefaultShipTo = address.isDefaultShipTo || false
        const isDefaultBillTo = address.isDefaultBillTo || false

        switch (true) {
            case isDefaultBillTo && isDefaultShipTo :
                return 'Default Shipping/Billing Address'

            case isDefaultShipTo :
                return 'Default Shipping Address'

            case isDefaultBillTo :
                return 'Default Billing Address'

            default :
                return ''
        }
    }

    const AddressBook = addressBook.map((address, index) => {
        return (
            <div
                class='CheckoutAddressBook'
                onClick={index => sendAddressInBook(index)}
            >
                <p
                    style={{display: address.isDefaultBillTo || address.isDefaultShipTo ? 'block' : 'none'}}
                >{defaultLabel(address)}</p>
                <p>{address.flatAddress}</p>
            </div>
        )
    })

    return (
        <div className='NightWrapper'>
            <div id='AddressInput'>
                <div style={{display: addressBook.length ? 'block' : 'none', marginBottom: '50px'}}>
                    <h4 style={{marginBottom: '20px'}}>Select Address in Your Address book</h4>
                    {!addressBook.length ? null : AddressBook}
                    <p className='GuideText' style={{textAlign: 'right'}}>Click to apply</p>
                </div>
                <div>
                    <h4 style={{marginBottom: '30px'}}>Enter New Address</h4>
                    <div className='AddressInputRow'>
                        <span>First Name</span>
                        <input type='text' id='firstName' placeholder='First Name' value={address.firstName} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Last Name</span>
                        <input type='text' id='lastName' placeholder='Last Name' value={address.lastName} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Company</span>
                        <input type='text' id='company' placeholder='Company Name' value={address.company} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Telephone</span>
                        <input type='text' id='telephone1' placeholder='Telephone Number' value={address.telephone1} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Street Address</span>
                        <input type='text' id='address1' placeholder='Address' value={address.address1} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Street Address2</span>
                        <input type='text' id='address2' placeholder='Apartment, Suite, etc. (optional)' value={address.address2} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>City</span>
                        <input type='text' id='city' placeholder='City' value={address.city} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Postal Code</span>
                        <input type='text' id='zip' placeholder='Postal Code' value={address.zip} onChange={changeAddress} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Province</span>
                        <Province id='province' selected={{code: address.provinceCode, name: address.province}} country={address.countryCode} action={changeProvince} />
                    </div>
                    <div className='AddressInputRow'>
                        <span>Country</span>
                        <Country id='country' selected={address.countryCode} action={changeCountry} />
                    </div>
                </div>
                <div style={{textAlign: 'right', margin: '50px 0'}}>
                    <span
                        className='RegSmallBtnLight'
                        style={{marginRight: '5px'}}
                        onClick={() => props.closeCallback()}
                    >Cancel</span>
                    <span
                        className='RegSmallBtn'
                        onClick={createNewAddress}>Save</span>
                </div>
            </div>
        </div>
    )
}


export default ShippingAddressInputBox



