// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import AddressBoxEdit from '../../partials/AddressBoxEdit'


const AddressBlock = props => {

    const [ isNew, toggleNew ] = useState(false)
    const [ isEdit, toggleEdit ] = useState(false)
    const [ address, setAddress ] = useState(props.address)

    const closeEdit = value => {
        if(!value) {
            toggleEdit(false)
            return
        }

        // setAddress(value)
        toggleEdit(false)

        // this.getAddressInfo()
    }


    const DefaultShippingIcon = () => {
        return (
            <span className='DefaultAddress'>
                Default Shipping Address
            </span>
        )
    }

    const DefaultBillingIcon = () => {
        return (
            <span className='DefaultAddress'>
                Default Billing Address
            </span>
        )
    }

    return (    
        <React.Fragment>
            { isEdit ? <AddressBoxEdit data={address} closeMe={closeEdit} /> : null }
            <div className='AddressBlock'>
                <div>
                    {address.isDefaultBillTo ? <DefaultBillingIcon /> : null}
                    {address.isDefaultShipTo ? <DefaultShippingIcon /> : null}
                </div>
                <p
                    id='name'
                    style={{
                        display: 'inline-block',
                        borderBottom: '1px solid #333333',
                        lineHeight: '14px',
                        marginBottom: '8px',
                        fontWeight: '500'
                    }}
                >
                    {address.firstName}{address.lastName ? ` ${address.lastName}` : null}
                </p>
                <p id='street'>{address.address1}{address.address2 ? `, ${address.address2}` : null}</p>
                <p id='city_province'>{address.city}{address.province ? `, ${address.province}` : null}{address.zip ? `, ${address.zip}` : null}</p>             
                <p id='country'>{address.country} (T : {address.telephone1})</p>
                <div className='AddressAction'>
                    <span className='material-icons' onClick={() => toggleEdit(isEdit ? false : true)}>edit</span>
                    <span className='material-icons'>delete</span>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddressBlock

