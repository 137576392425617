// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { formatCurrency } from '../../helper/currencyFormatter'


const CartBottom = props => {

    const navigate = useNavigate()
    const [ cartKey, setCartKey ] = useState('')

    useEffect(() => {
        const cartKey = localStorage.getItem('cart_key') || null
        setCartKey(cartKey)
    }, [])


    const CheckoutButton = () => {
        if (!props.total) { 
            return (
                <React.Fragment>
                    <span className='RegBtn' style={{opacity: 0.5}}>Proceed to Checkout</span>
                </React.Fragment>
                )
        } else {
            return (
                <Link to = {`/checkout/${cartKey}`} >
                    <span className='RegBtn'>Proceed to Checkout</span>
                </Link>
            )
        }
    }

    return (
        <div id = 'CartBottom'>
            <div id = 'CartPrice'>
                <h3>{formatCurrency(props.total)}</h3>
                <p className='GuideText'>Discount code can be applied at the checkout page.</p>
                <p className='GuideText'>Shipping fee, or additional charge can be added during checkout.</p>
            </div>
            <span className='RegBtnLight' style={{marginRight: '10px'}} onClick={() => navigate('/')}>Back to Main</span>
            <CheckoutButton />
        </div>
    )
}


export default CartBottom



