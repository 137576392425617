// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { addItemToCart } from '../../api/cart/cart'
import { createBuyNowCart } from '../../api/cart/buy-now'
import { createWishList } from '../../api/wish-list/wish-list'
import { readPriceByProductId, readStockByProductId, readOptionByProductId } from '../../api/product/product'
import Rate from './blocks/Rate'
import Option from './blocks/Option'
import showNotice from '../../helper/noticeDialog'
import showAlert from '../../helper/alertDialog'
import { getConfig } from '../../helper/getConfig'
import { getProductImageUrl } from '../../helper/getProductImageUrl'
import { formatCurrency } from '../../helper/currencyFormatter'


const ProductTop = props => {

    const navigate = useNavigate()
    const [ isAvailable, setAvailability ] = useState(true)
    const [ product, setProduct ] = useState({})
    const [ qty, setQty ] = useState(1)
    const [ optionSelected, setOptionSelected ] = useState([])
    const [ optionIds, setOptionIds ] = useState([])
    const [ optionPrice, setOptionPrice ] = useState(0)
    const [ imageSelected, setImage ] = useState(props.info.imageBase)

    useEffect(() => {
        if (props.productId) {
            getPrice(props.productId)
            getStock(props.productId)
            getOption(props.productId)
        }
    }, [ props.productId ])

    const getPrice = async productId => {

        const fetchPrice = await readPriceByProductId(productId)

        if (fetchPrice.result !== 'success' || !fetchPrice.info) {
            setAvailability(false)
        } else {
            const priceData = fetchPrice.info
            const dataToSet = {
                regularPrice: priceData.regularPrice,
                specialPrice: priceData.specialPrice
            }

            setProduct(prevState => {
                return { ...prevState, ...dataToSet }
            })
        }
    }

    const getStock = async productId => {

        const fetchStock = await readStockByProductId(productId)

        if (fetchStock.result !== 'success' || !fetchStock.info) {
            setAvailability(false)
        } else {
            const stockData = fetchStock.info
            const dataToSet = {
                stockAvailability: stockData.stockAvailability,
                stockQty: stockData.stockQty,
                threshold: stockData.threshold
            }

            setProduct(prevState => {
                return { ...prevState, ...dataToSet }
            })
        }
    }

    const getOption = async productId => {
        
        const fetchOptions = await readOptionByProductId(productId)

        // Don't care options is empty array or not.
        if (['success', 'no-record'].includes(fetchOptions.result) && fetchOptions.info) {
            const optionData = fetchOptions.info
            setProduct(prevState => {
                return { ...prevState, option: optionData }
            })
        } else {
            setAvailability(false)
        }
    }

    const changeQuantity = event => {
        const targetId = event.target.id
        const direct = parseInt(event.target.value)
 
        if (targetId === 'minus' && qty !== 0 ) {
            setQty(qty - 1)
        } else if (targetId === 'minus' && qty <= 0) { 
            return
        } else if (targetId === 'plus') {
            setQty(qty + 1)
        } else {
            setQty(direct)
        }
    }

    const changeOption = selected => {

        setOptionSelected(selected)

        let optionPrice = 0
        let optionIds = []
        for (const value of selected) {
            optionPrice = optionPrice + parseFloat(value.price)
            optionIds = [ ...optionIds, value.id ]
        }

        setOptionIds(optionIds)
        setOptionPrice(optionPrice)
    }

    const isOptionSelected = () => {

        if (!product.option?.length) { return true }
        if (optionSelected <= 0 || !optionSelected) { return false }

        const optionLength = Array.from(new Set(product.option.map(data => data.option))).length
        const optionSelectedLength = Array.from(new Set(optionSelected.map(data => data.optionName))).length

        return optionLength === optionSelectedLength ? true : false
    }

    const addToCart = async () => {

        if (!isOptionSelected) {
            showNotice('Please choose option(s).')
            return
        }

        const allowBackorder = getConfig('allow_backorder') || 'disallow'

        if (!qty > product.stockQty - product.threshold && allowBackorder === 'disallow') {
            showNotice(`You can not add more than ${product.stockQty - product.threshold}`)
            return
        }

        if (!product.stockAvailability && allowBackorder === 'disallow') {
            showNotice('Out of stock.')
            return
        }

        if (!isAvailable) {
            showNotice('Could not add item. There is a problem with product information.')
            return
        }

        if (qty <= 0) {
            showNotice('Please enter more than 1')
            return
        }

        if (!isOptionSelected) {
            showNotice('Please choose option(s).')
            return
        }

        const dataToSend = {
            productId: props.info.id,
            quantity: qty,
            option: optionIds
        }

        if (props.customerId) {
            Object.assign(dataToSend, { customerId: props.customerId })
        }

        const addToCartRequest = await addItemToCart(dataToSend)

        if (addToCartRequest.result === 'success') {
            setQty(1)
            const alert = await showAlert(
                'Item Added Successfully!',
                'View Cart',
                'Continue Shopping'
            )

            if (alert === 'one') {
                navigate('/cart')
            }

            return

        } else {
            showNotice('Something went wrong! Please try again.')
            setQty(1)
        }
    }

    const addToBuyNow = async () => {

        if (!isOptionSelected) {
            showNotice('Please choose option(s).')
            return
        }

        const allowBackorder = getConfig('allow_backorder') || 'disallow'

        if (!qty > product.stockQty - product.threshold && allowBackorder === 'disallow') {
            showNotice(`You can not add more than ${product.stockQty - product.threshold}`)
            return
        }

        if (!product.stockAvailability && allowBackorder === 'disallow') {
            showNotice('Out of stock.')
            return
        }

        if (!isAvailable) {
            showNotice('Could not add item. There is a problem with product information.')
            return
        }

        if (qty <= 0) {
            showNotice('Please enter more than 1')
            return
        }

        if (!isOptionSelected) {
            showNotice('Please choose option(s).')
            return
        }

        const dataToSend = {
            productId: props.info.id,
            quantity: qty,
            option: optionIds
        }

        if (props.customerId) {
            Object.assign(dataToSend, { customerId: props.customerId })
        }

        const buyNowRequest = await createBuyNowCart(dataToSend)

        if (buyNowRequest.result === 'success' && buyNowRequest.info?.key) {
            const urlToRedirect = `/checkout/buy-now/${buyNowRequest.info.key}`
            navigate(urlToRedirect)
            return
        } else {
            showNotice('Something went wrong! Please try again.')
            setQty(1)
        }
    }


    const addToWishList = async () => {

        if (!isOptionSelected) {
            showNotice('Please choose option(s).')
            return
        }

        if (!props.customerId) {
            const alert = await showAlert(
                'To create your wish list, please sign in. If you are new, please create an account.',
                'Sign In/Up',
                'Cancel'
            )

            if (alert === 'one') {
                navigate('/sign')
            }

            return
        }

        const dataToSend = {
            customerId: props.customerId,
            productId: props.info.id,
            quantity: qty,
            option: optionIds
        }

        const addToWishListRequest = await createWishList(dataToSend)

        if (addToWishListRequest.result === 'success') {
            showNotice('Item added to wish list successfully!')
        } else {
            showNotice('Something went wrong!')
        }
    }

    const Price = () => {
        const regularPrice = product.regularPrice ? product.regularPrice : null
        const specialPrice = product.specialPrice ? product.specialPrice : null

        if (regularPrice && specialPrice) {
            return(
                <React.Fragment>
                    <div className='PriceBlock'>
                        <p>Regular Price</p><span id='PriceMax'>{formatCurrency(regularPrice)}</span>
                    </div>
                    <div className='PriceBlock'>
                        <p>Special Price</p><span id='PriceMin'>{formatCurrency(specialPrice)}</span>
                    </div>
                </React.Fragment>
            )
        } else if (regularPrice && !specialPrice) {
            return (
                <div className='PriceBlock'>
                    <p>Price</p><span id='PriceMin'>{formatCurrency(regularPrice)}</span>
                </div>
            )
        } else {
            return (
                <div className='PriceBlock'>
                    <p>Price</p><span id='PriceMin'>Not Available</span>
                </div>
            )
        }
    }

    const SubTotal = () => {
        let subtotal

        if (!product.specialPrice) {
            subtotal = (product.regularPrice + optionPrice) * qty
        } else {
            subtotal = (product.specialPrice + optionPrice) * qty
        }
        
        return (
            <div id='ProductTotal'>
                <p>Total</p><span>{formatCurrency(subtotal)}</span>
            </div>
        )
    }

    const AdditionalImages = () => {

        const images = props.info.imageAdditional ? props.info.imageAdditional.split(',') : []

        if (!images.length) {
            return null
        } else {
            return images.map(image => {
                return (
                    <div className='AdditionalImageWrapper'>
                        <img
                            src={getProductImageUrl(image)}
                            onClick={() => setImage(image)}
                            alt='product'
                        />
                    </div>
                )
            })
        }
    }

    
    return (
        <React.Fragment>
            <div id='ProductBread'>
                <span>Home</span>
                <span> &#8250; {props.info.name}</span>
            </div>
            <div id='ProductTopMain'>
                <div id='ProductLeft'>
                    <div id='ProductLeftAdditional'>
                        <div id='AdditionalText'><span>Images</span></div>
                        <div className='AdditionalImageWrapper'>
                            <img
                                src={getProductImageUrl(props.info.imageBase)}
                                onClick={() => setImage(props.info.imageBase)}
                                alt='product'
                            />
                        </div>
                        <AdditionalImages />
                    </div>
                    <img width='700' height='700' src={getProductImageUrl(imageSelected || props.info.imageBase)} alt='product' />
                </div>
                <div id='ProductRight'>
                    <div className='ProductTxt'>
                        <div id='ProductSku'>
                            <p>Item Code : {props.info.sku}</p>
                            <Rate productId={props.productId} />
                        </div>
                        <div id='ProductTitle'>
                            <h1>{props.info.name}</h1>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <span>{product.stockAvailability ? 'In Stock' : 'Out of Stock'}</span>
                        </div>
                        <Price />
                    </div>
                    <div
                        id='ShortDescriptionBlock'
                        style={{
                            borderTop: props.info.shortDescription ? '1px solid #888888' : 'none',
                            borderBottom: props.info.shortDescription ? '1px solid #888888' : 'none',
                            padding: props.info.shortDescription ? '20px 0' : '0'
                        }}
                        dangerouslySetInnerHTML={{__html: props.info.shortDescription}}
                    ></div>
                    {product.option?.length > 0 ? <Option data={product.option} changeCallback={changeOption} /> : null }
                    <div id='ProductAdd'>
                        <p>Quantity</p>
                        <div id='ProductAddBox'>
                            <span id='minus' className='material-icons' onClick={changeQuantity}>remove</span>
                            <input type='text' value={qty} onChange={changeQuantity} />
                            <span id='plus' className='material-icons' onClick={changeQuantity}>add</span>
                        </div>
                    </div>
                    <SubTotal />
                    <div id='ProductAddButton'>
                        <span className='RegBtn' onClick={addToBuyNow}>Buy Now!</span>
                        <div id='AddToContainer'>
                            <span className='RegBtn' onClick={addToCart}>Add to Cart</span>
                            <span className='RegBtnLight' onClick={addToWishList}>Add to Wish List</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default ProductTop