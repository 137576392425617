// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgetPassword from './ForgetPassword'
import './style.css'


const Sign = props => {

    const [ page, setPage ] = useState('sign-in')
    const pageName = ['sign-in', 'sign-up', 'forget-password']

    const title = [
        'Sign In',
        'Sign Up',
        'Forget Your Password?'
    ]

    const subTitle = [
        `Your journey start here. Please sign in to your accout.`,
        `Create an account on Mustom demo store, the world's leading online store.`,
        `We'll email you instructions on how to reset your password.`
    ]

    return (
        <React.Fragment>
            <h1 className='PageTitle2'>{title[pageName.indexOf(page)]}</h1>
            <p className='SubtitleTxt'>{subTitle[pageName.indexOf(page)]}</p>
            <div className='RegGrayContainer' style={{marginBottom: '100px'}}>
                {page === 'sign-in' ? <SignIn changePage={page => setPage(page)} /> : null}
                {page === 'sign-up' ? <SignUp changePage={page => setPage(page)} /> : null}
                {page === 'forget-password' ? <ForgetPassword changePage={page => setPage(page)} /> : null}
            </div>
        </React.Fragment>
    )
}


export default Sign