// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { readMiniCart } from '../../../api/cart/cart'


const HeaderRight = props => {

    const [ qty, setQty ] = useState(0)
    const [ keyword, setKeyword ] = useState('')
    const [ isSearch, toggleSearch ] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setCartIcon()
    }, [])

    const setCartIcon = async () => {

        const fetchMiniCart = await readMiniCart()

        if (fetchMiniCart?.result !== 'success' || !fetchMiniCart?.info?.totalQty) {
            return
        }
        
        setQty(fetchMiniCart.info.totalQty)
    }

    const keyPressHandler = key => {
        if (key === 'Enter') { submitHandler() }
    }

    const submitHandler = () => {
        if (!keyword) {
            toggleSearch(true)
        } else {
            navigate(`/search/${keyword}`)
            setKeyword('')
            toggleSearch(false)
        }
    }

    const iconStyle = {
        color: props.isHome ? '#FFFFFF' : '#333333',
        marginLeft: '10px'
    }

    const inputStyle = {
        borderBottom: props.isHome ? '1px solid #FFFFFF' : '1px solid #333333',
        color: props.isHome ? '#FFFFFF': '#333333',
        opacity: isSearch ? '1' : '0'
    }

    return (
        <div id='HeaderRight'>
            <input
                type='text'
                style={inputStyle}
                value={keyword}
                onChange={event => setKeyword(event.target.value)}
                onKeyPress={event => keyPressHandler(event.key)}
                ></input>
            <span
                className='material-icons'
                style={iconStyle}
                onClick={submitHandler}
            >search</span>
            <Link to='/cart'>     
                <span className='material-icons' style={iconStyle}>shopping_basket</span>
                <span style={{verticalAlign: 'super', fontSize: '10px'}}>{qty}</span>
            </Link>
        </div>
    )
}


export default HeaderRight