// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import HeaderPC from './HeaderPC'
import HeaderMobile from './HeaderMobile'
import './style.css'


const Header = props => {
    return props.isMobile ? <HeaderMobile /> : <HeaderPC isHome={props.isHome} />
}


export default Header