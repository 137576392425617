// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


const validateInput = (input, rule, callback) => {


    if (!rule || !input) { return }

    let callbackMessage = ''

    // Length Validation
    if (rule.maxLen) {
        const inputLength = input.length
        if (inputLength > rule.maxLen) {
            addMessage(`The length of input value must be ${rule.maxLen} or fewer.`)
        }
    }

    // Minimum Value Validation
    if (rule.minValue) {
        if (!isNaN(input) && input < rule.minValue) {
            addMessage(`Please enter equal or greater than ${rule.maxValue} value.`)
        }
    }

    // Maximum Value Validation
    if (rule.maxValue) {
        if (!isNaN(input) && input > rule.maxValue) {
            addMessage(`Please enter equal or less than ${rule.maxValue} value.`)
        }
    }

    // Type Validation
    if (rule.type) {
        switch (rule.type) {
            case 'number':
                checkNumber(input)
                break
            case 'integer':
                checkInteger(input)
                break
            case 'wholeNumber':
                checkWholeNumber(input)
                break
            case 'email':
                checkEmail(input)
                break
            case 'url':
                checkUrl(input)
                break
            case 'ip':
                checkIp(input)
                break
            case 'path':
                checkPath(input)
                break
            case 'alphaNum':
                checkAlphaNum(input)
                break
            case 'password':
                checkPassword(input)
                break
            default:
                break
        }
    }


    function checkNumber(input) {
        if (isNaN(input)) { addMessage(`Please enter numbers only.`) }
    }

    function checkInteger(input) {
        if (input % 1 !== 0) { addMessage(`Please enter a whole number(integer) without decimal point. (e.g. 8888)`) }
    }

    function checkWholeNumber(input) {
        const regex = /^(0|([1-9]\d*))$/
        const checker = regex.test(input)
        if (!checker) { addMessage(`Please enter a natual number without decimal point. Negative number is not allowed.`) }
    }

    function checkEmail(input) {
        const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        const checker = regex.test(input)
        if (!checker) { addMessage(`Please enter valid email address. (e.g. john_doe@email.com)`) }
    }

    function checkUrl(input) {
        const regex = /^http[s]?:\/\//
        const checker = regex.test(input)
        if (!checker) { addMessage(`Please enter valid URL start with 'http://' or 'https://'. (e.g. https://mustom.com)`) }
    }

    function checkIp(input) {
        const regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/
        const checker = regex.test(input)
        if (!checker) { addMessage(`Please enter valid IP address. (e.g. 110.22.222.22)`) }
    }

    function checkPath(input) {
        const regex = /^[A-Za-z0-9_-]*$/i
        const checker = regex.test(input)

        if (!checker) { addMessage(`Only allowed to enter alphabet letters, numbers, hyphens(-), and underscores(_).
        Space, period(.), and special charactors are not allowed.`) }
    }

    function checkAlphaNum(input) {
        const regex = /^[A-Za-z0-9]*$/
        const checker = regex.test(input)

        if (!checker) { addMessage(`Only allowed to enter alphabet letters, numbers.`) }
    }

    function checkPassword(input) {
        const regex = /^[A-Za-z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]*$/
        const checker = regex.test(input)
        if (!checker) { addMessage(`Invalid password format.`) }
    }

    function addMessage(message) {
        callbackMessage = callbackMessage ? callbackMessage + '\n'+ message : message
    }


    callback(callbackMessage)
}


export default validateInput