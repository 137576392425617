// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LoginContext from '../../context/LoginContext'
import ProductTop from './ProductTop'
import ProductDescription from './ProductDescription'
import ProductReview from './ProductReview'
import { readProductBySlug } from '../../api/product/product'
import showNotice from '../../helper/noticeDialog'
import config from '../../config/general.json'
import './style.css'


const Product = props => {

    const { slug } = useParams()
    const user = useContext(LoginContext)
    const [ product, setProduct ] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0)
        getProductInfo(slug)
    }, [])


    const getProductInfo = async slug => {

        const fetchProduct = await readProductBySlug(slug)

        if (fetchProduct.result !== 'success' || !fetchProduct.info) {
            showNotice('Something went wrong. Please try again later.')
            return
        }

        setProduct(fetchProduct.info)
        storeViewedItem(fetchProduct.info?.id)
    }


    // Set recently viewed item info into local storage
    const storeViewedItem = productId => {
        const itemLimit = config.viewedItemLimit || 20
        const storedItem = localStorage.getItem('viewed-item')

        localStorage.setItem('viewed-item', productId)
        
        if (storedItem) {
            const storedToArray = storedItem.split(',')
            
            if (storedToArray.includes(productId.toString())) {
                return
            }

            const newList = [ productId, ...storedToArray.slice(0, itemLimit - 1) ]
            localStorage.setItem('viewed-item', newList.toString())

        } else {
            localStorage.setItem('viewed-item', productId)
        }
    }


    const topInfo = {
        id: product.id,
        name: product.name,
        sku: product.sku,
        shortDescription: product.shortDescription,
        imageBase: product.imageBase,
        imageAdditional: product.imageAdditional
    }


    return (
        <React.Fragment>
            <div className='RegContainer'>
                <ProductTop info={topInfo} productId={product.id} customerId={user.customerId} />
            </div>
            <div id='ProductNameBand'>
                <div className='RegContainer'>
                    <h1>{product.name}</h1>
                </div>
            </div>
            <div className='RegContainer'>
                <ProductDescription info={product.description} customerId={user.customerId} />
                <ProductReview productId={product.id} customerId={user.customerId} />
            </div>
        </React.Fragment>
    )

}


export default Product