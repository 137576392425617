// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import { signUp } from '../../api/customer/signUp'
import { getConfig } from '../../helper/getConfig'
import showNotice from '../../helper/noticeDialog'


const SignUp = props => {

    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ repeat, setRepeat ] = useState('')
    const [ isConsent, toggleConsent ] = useState(false)
    
    const submitAccount = async () => {
        if (!email || !password || !repeat) {
            showNotice('Please enter require field!')
            return
        } 
        
        if (password !== repeat) {
            showNotice('Password and confirm password must match!')
            return
        }

        if (!isConsent) {
            showNotice('You must accept the terms of service!')
            return
        }
        
        // IMPORTANT : This case is 'email is used as a user name' configuraton.
        // Change your storefront theme, if you want to use user name

        const dataToSend = {
            username: email,
            email: email,
            password: password
        }

        const signUpRequest = await signUp(dataToSend)

        if (signUpRequest.result === 'duplicated-data') {
            showNotice(`The Email address you have entered is already taken. Please use other email address!`)
            return
        } 
        
        if (signUpRequest.result !== 'success') {
            showNotice(`Something went wrong! Please try again.`)
            return
        }

        const isConfirmationNeeded = getConfig('require_email_verification') || false

        if (!isConfirmationNeeded) {
            showNotice(`Congratulation, your account has been successfully created!`)
        } else {
            showNotice(`We sent an email to ${email}! Please check your inbox and find confirmation email we've sent you.`)
        }

        setEmail('')
        setPassword('')
        setRepeat('')
        toggleConsent(false)
    }

    return (
        <React.Fragment>
            <div className='HalfContainer' style={{borderRight: '1px solid #E4E4E4'}}>
                <div className='SignLeft'>
                    <p>Email</p>
                    <input
                        type='text'
                        name='email'
                        value={email}
                        placeholder='e.g. jone-doe@mustom.com'
                        onChange={event => setEmail(event.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <p>Password</p>
                    <input
                        name='password'
                        type='password'
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <p>Confirm Password</p>
                    <input
                        name='repeat'
                        type='password'
                        value={repeat}
                        onChange={event => setRepeat(event.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <input
                        type='checkbox'
                        name='subscription'
                        style={{verticalAlign: 'middle'}}
                        value={isConsent}
                        onChange={() => toggleConsent(isConsent ? false : true)}
                    />
                    <span style={{paddingLeft: '10px', verticalAlign: 'middle'}}>I have read and agree to the terms of service</span>
                    <span className='FullWidthBtn' style={{margin: '20px 0'}} onClick={submitAccount}>Submit</span>
                    <p style={{textAlign: 'right', cursor: 'pointer'}} onClick={() => props.changePage('sign-in')}>Back to Sign-in</p>
                </div>
            </div>
            <div className='HalfContainer'>
                <div className='SignRight'>
                    <h5 style={{marginBottom: '20px'}}>Create New Account</h5>
                    <p style={{marginBottom: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam, quam at eleifend eleifend, tellus ex faucibus elit, quis pharetra sapien purus eu ex. Pellentesque rhoncus molestie elit ut elementum.</p>
                    <p style={{marginBottom: '20px'}}>Nulla semper malesuada urna, ac imperdiet leo tincidunt sed. Aliquam vulputate neque at dui ullamcorper, sit amet tristique purus volutpat.</p>
                    <p style={{marginBottom: '20px'}}>Quisque gravida elit a congue malesuada. Ut malesuada convallis turpis nec auctor. Pellentesque molestie magna lectus, interdum faucibus eros egestas et. In enim massa, imperdiet pharetra nunc varius, posuere rutrum urna.</p>                    
                    <p style={{marginBottom: '20px'}}>Curabitur mattis varius massa quis pellentesque. Donec non orci faucibus, luctus nunc sed, congue lectus. Quisque non felis non elit tincidunt dapibus. Aenean ac magna vitae sapien fringilla congue id quis metus. </p>                    
                </div>
            </div>
            <div className='ClearFix'></div>
        </React.Fragment>  
    )
}


export default SignUp