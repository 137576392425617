// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'


const LoginContext = React.createContext({
    customerId: '',
    updateStatus: () => {}
})


export default LoginContext