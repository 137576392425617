// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import HomeBanner from './HomeBanner'
import HomeHot from './HomeHot'
import './style.css'


const Home = () => {
    return (
        <React.Fragment>
            <HomeBanner />
            <HomeHot />
        </React.Fragment>
    )
}


export default Home



