// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import Image from './promo-banner.png'


const ProductDescription = props => {
    return (
        <div className='ProductBottomBox' style={{borderBottom: '1px solid #E4E4E4'}}>
            {/* <h3 className='ProductBottomTitle'>Detailed Information</h3> */}
            {/* <div className='ProductBanner' style={{backgroundImage: `url(${Image}`}}>
                <h1>PROMO BANNER</h1>
            </div> */}
            <div className='ProductDescription' dangerouslySetInnerHTML={{__html: props.info}}>
            </div>
        </div>
    )
}


export default ProductDescription


