// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import {
    readAvailablePaymentMethod,
    executePaymentService
} from '../../api/service-registry/service'
import { applyDiscount, cancelDiscount } from '../../api/discount/discount'
import showNotice from '../../helper/noticeDialog'



const PaymentMethod = props => {

    const [ selected, toggleSelected ] = useState('')
    const [ promoCode, setPromoCode ] = useState('')
    const [ availableMethods, setAvaiableMethods ] = useState([])
    const [ isCouponApplied, setCouponSubmission ] = useState(false)

    useEffect(() => {

        if (props.shippingMethod && props.shippingMethod === selected) {
            toggleSelected(props.shippingMethod)
        }

        if (props.orderId && !availableMethods.length) {
            getAvailablePaymentMethod(props.orderId)
        }
    }, [ props ])


    const getAvailablePaymentMethod = async orderId => {
        const fetchAvailableMethod = await readAvailablePaymentMethod(orderId)

        if (fetchAvailableMethod.result === 'service-not-found') {
            setAvaiableMethods([])
            return
        }

        if (fetchAvailableMethod.result !== 'success' || !fetchAvailableMethod.info) {
            showNotice('Sorry. Failed to retrieve payment method data. Please try again later.')
            return
        }

        setAvaiableMethods(fetchAvailableMethod.info)
    }

    const updatePaymentMethod = async event => {
        
        toggleSelected(event.target.id)

        const updateRequest = await executePaymentService(event.target.id, props.orderId)

        if (updateRequest.result !== 'success') {
            toggleSelected('')
            showNotice('Failed to update information. Please try again.')
            return
        } else {
            props.updateCallback()
        }
    }

    const applyPromoCode = async () => {

        // Cancel Coupon Code
        if (isCouponApplied) {

            const cancelRequest = await cancelDiscount({
                couponCode: promoCode,
                orderId: props.orderId
            })

            if (cancelRequest !== 'success') {
                showNotice('Failed to cancel discount. Please try again.')
            } else {
                setPromoCode('')
                setCouponSubmission(false)
            }

            props.updateCallback()
            return
        }

        if (!promoCode.trim().length) {
            showNotice('Please enter your promo code!')
            return
        }

        const applicationRequest = await applyDiscount({
            couponCode: promoCode,
            orderId: props.orderId
        })

        if (applicationRequest !== 'success') {
            showNotice('Fail to apply coupon code. Please use other coupon, or try again later.')
            setPromoCode('')
        } else {
            setCouponSubmission(true)
        }

        props.updateCallback()
    }

    const PaymentMethodList = availableMethods.map((method, index) => {
        return (
            <div style={{marginBottom: '10px'}}>
                <label>
                    <input
                        type='radio'
                        id={method.serviceCode}
                        checked={selected === method.serviceCode ? true : false }
                        onChange={updatePaymentMethod}
                    />
                    <span style={{fontWeight: 500, verticalAlign: 'middle'}}>{method.title}</span>
                </label>
                <p style={{marginLeft: '18px'}}>
                {method.introduction}
                </p>
            </div>
        )
    })

    const NoMethodAvailable = () => {
        return (
            <div>
                <span>Sorry. There are no payment method available for your order.</span>
            </div>
        )
    }

    return (
        <div style={{marginTop: '50px'}}>
            <span className='CheckoutMenu'>Payment Method</span>
            <div className='CheckoutMenuContent'>
                <div>
                { !availableMethods.length ? <NoMethodAvailable /> : PaymentMethodList }
                </div>
                <p className='GuideText' style={{marginTop: '30px'}}>Enter your promo code if you have one.</p>

                <div className='CheckoutInputWrapper'>
                    <input type='text' id='couponCode' placeholder='Enter Promo Code' value={promoCode} onChange={event => setPromoCode(event.target.value)} />
                    <span
                        className='InputButton'
                        onClick={applyPromoCode}
                    >{ isCouponApplied ? 'Cancel' : 'Apply'}</span>
                </div>
            </div>
        </div>
    )
}


export default PaymentMethod
