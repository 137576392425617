// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import BannerImg from './mainbanner.jpg'


const MainBanner = () => {

    const Banner = {
        backgroundImage: 'url(' + BannerImg + ')'
    }

    return (
        <div className='WideBanner' style={Banner}>
            <div id='BannerTextContainer'>
                <h1 className='BannerBigText'>Hello Mustom!</h1>
                <p className='BannerSmallText'>Duis aute irure dolor in reprehenderit</p>
                <p className='BannerSmallText'>Excepteur sint occaecat cupidatat non proident</p>
                <span id='BannerBtn'>Move</span>
            </div>
        </div>
    )
}


export default MainBanner
