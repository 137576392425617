// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { sendInformation } from '../../helper/crudHandler'


export const requestToken = async orderId => {
    const tokenRequest = await sendInformation('/mustom-braintree/token', {
        orderId: orderId
    })

    return tokenRequest
}

export const sendNonce = async (nonce, orderId) => {
    const confirmPayment = await sendInformation('/mustom-braintree/checkout', {
        nonce: nonce,
        orderId: orderId
    })

    return confirmPayment
}


