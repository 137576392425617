// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import ShippingAddressInputBox from './blocks/ShippingAddressInputBox'


const ShippingAddress = props => {

    const [ showInput, toggleInput ] = useState(false)
    const [ shipTo, setShipTo ] = useState({})


    useEffect(() => {

        if (props.shippingAddress) {
            setShipTo(props.shippingAddress)
        }

    }, [ props ])

    const AddressSelected = () => {
        return (
            <div id='CheckoutAddressContainer'>
                <div style={{marginBottom: '5px'}}>
                    <span>{shipTo.firstName}</span>
                    <span>{shipTo.firstName && shipTo.lastName ? ' ' : null}</span>
                    <span>{shipTo.lastName}</span>
                    {shipTo.company ? <p>{shipTo.company}</p> : null}
                </div>
                <div>
                    <span>{shipTo.address1}</span>
                    <span>{shipTo.address2 && shipTo.address2 ? ', ' : null}</span>
                    <span>{shipTo.address2}</span>
                    <span>{shipTo.address3 ? ', ' : null}</span>
                    <span>{shipTo.address3}</span>
                </div>
                <div>
                    <span>{shipTo.city}</span>             
                    <span>{shipTo.city && shipTo.province ? ', ' : null}</span>
                    <span>{shipTo.province}</span>
                    <span>{shipTo.zip ? ', ' : null}</span>
                    <span>{shipTo.zip}</span>
                    <span>{shipTo.country ? ', ' : null}</span>
                    <span>{shipTo.country}</span>
                </div>
                <div style={{marginTop: '5px'}}>
                    <span>{shipTo.telephone1 ? 'Tel : ' : null}</span>
                    <span>{shipTo.telephone1}</span>
                    <span>{shipTo.telephone2 ? ', Tel2 : ' : null}</span>
                    <span>{shipTo.telephone2}</span>
                </div>
            </div>
        )
    }

    const AddressNotExist = () => {
        return (
            <div id='CheckoutAddressContainer' style={{textAlign: 'center'}}>
                <span id='AddAddressButton' onClick={() => toggleInput(true)}>Add Address</span>
            </div>
        )
    }

    return (
        <React.Fragment>
            {showInput ?
                <ShippingAddressInputBox
                    customerId={props.customerId}
                    orderId={props.orderId}
                    updateCallback={() => props.updateCallback()}
                    closeCallback={() => toggleInput(false)}
                /> :
                null
            }
            <div style={{marginBottom: '50px'}}>
                <span className='CheckoutMenu'>Shipping Address</span>
                <div className='CheckoutMenuContent'>
                    <p
                        className='CheckoutAddressChangeButton'
                        style={{display: shipTo.createdAt === shipTo.updatedAt ? 'none' : 'block'}}
                        onClick={() => toggleInput(true)}
                    >Change</p>
                    {shipTo.createdAt === shipTo.updatedAt ? <AddressNotExist /> : <AddressSelected />} 
                </div>
            </div>
        </React.Fragment>
    )
}


export default ShippingAddress



