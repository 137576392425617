// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { sendInformation } from '../../helper/crudHandler'


export const signIn = async data => {
    return await sendInformation('/auth/customer/sign-in', data)
}
