// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { sendInformation } from '../../helper/crudHandler'


export const signUp = async data => {
    return await sendInformation('/auth/customer/sign-up', data)
}
