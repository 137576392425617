// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { getConfig } from './getConfig'
import placeholder from '../media/image/placeholder.svg'

export const getProductImageUrl = path => {
    if (!path) { 
        return placeholder
    }

    const mediaUrl = getConfig('url_media')
    let mediaUrlWithoutSlash

    if (mediaUrl) {
        mediaUrlWithoutSlash = mediaUrl.endsWith('/') ? mediaUrl.slice(0, -1) : mediaUrl
    }

    const imageUrl = mediaUrl ? `${mediaUrlWithoutSlash}/image/product/${path}` : `${process.env.REACT_APP_ENGINE}/media/image/product/${path}`

    return imageUrl
}

  
