// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import CartBottom from './CartBottom'
import CartItem from './CartItem'
import { getCart } from '../../api/cart/cart'
import './style.css'


const Cart = () => {
    
    const [ cartItem, setCartItem ] = useState([])
    const [ totalAmount, setTotalAmount ] = useState(0)

    useEffect(() => {
        getCartInfo()
    }, [])

    const getCartInfo = async () => {

        const cartKey = localStorage.getItem('cart_key')

        if (!cartKey) { return }

        try {
            const fetchCart = await getCart(cartKey)

            if (fetchCart.result !== 'success') {
                console.error('Error: Cart retrieval failed.')
                return
            }

            const { total, item } = fetchCart.info

            if (total && item) {
                const optionRefined = item.map(item => {
                    if (item.option) {
                        const optionToSubstitute = item.option
                            .map(option => `${option.option} : ${option.value}`)
                            .join(' ')
                        return { ...item, option: optionToSubstitute }
                    }
                    return item
                })

                setCartItem(optionRefined)
                setTotalAmount(total)

            } else {
                console.error('Error: Cart info is incomplete.')
            }
        } catch (error) {
            console.error('Error fetching Cart', error)
        }
    }
 
    const EmptyText = () => {
        return (
            <tr style={{height: '300px'}}>
                <td colSpan='6' align='center'>Your cart is empty.</td>
            </tr>
        )
    }

    const ItemList = cartItem.map((item, index) => {
        return (
            <React.Fragment>
                <CartItem itemData={cartItem[index]} updateCallback={() => getCartInfo()} />
            </React.Fragment>
            )
        }
    )

    return (
        <React.Fragment>
            <h1 className='PageTitle'>Shopping Cart</h1>  
            <div className='RegGrayContainer'>
                <div style={{minHeight: '400px'}}>
                    <table width='100%'>
                        <thead>
                            <tr>
                                <th width='10%'>Image</th>
                                <th width='40%'>Title</th>
                                <th width='10%'>Unit Price</th>
                                <th width='20%'>Quantity </th>
                                <th width='10%'>Subtotal</th>
                                <th width='10%'>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            { !cartItem.length ? <EmptyText /> : ItemList }
                        </tbody>                                          
                    </table>
                </div>
                <CartBottom total = {totalAmount} />
            </div>
        </React.Fragment>
    )
}


export default Cart