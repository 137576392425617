// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import PostReview from './blocks/PostReview'
import ReviewList from './blocks/ReviewList'


const ProductReview = props => {

    return (
        <div className='ProductBottomBox'>
            <h1 className='ProductBottomTitle'>Customer Reviews</h1>
            <p className='ProductBottomSubTitle'>What our customers say about this item!</p>
            <div id='ReviewContainer'>
                <PostReview productId={props.productId} customerId={props.customerId} />
                <ReviewList productId={props.productId} />
            </div>
        </div>
    )
}


export default ProductReview

