// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { readPageBySlug } from '../../api/cms/page'


const Page = props => {
    
    const [ content, setContent ] = useState({})
    const { slug } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
        getContent(slug)
    }, [])


    const getContent = async slug => {
        const fetchPage = await readPageBySlug(slug)
        
        if (fetchPage.result !== 'sucess' || !fetchPage.info) {
            return
        }
        
        setContent(fetchPage.info)
    }

    return (
        <div className='RegContainer' style={{minHeight: '80vh'}}>
            {content.description}
        </div>        
    )
}


export default Page




