// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readCategory } from '../../../api/category/category'
import { Link } from 'react-router-dom'


const HeaderLeft = props => {

    const [ categories, setCategories ] = useState([])
    const [ subCategory, setSubCategory ] = useState('')


    useEffect(() => {
        getCategory()
    }, [])


    const getCategory = async () => {
        const fetchCategories = await readCategory()
        if (fetchCategories.result !== 'success' || !Array.isArray(fetchCategories.info)) { return }
        setCategories(fetchCategories.info)
    }
    
    const spanStyle = {
        color: props.isHome ? '#FFFFFF' : '#333333',
        margin: '0 10px'
    }


    const showSubCategory = parentId => {
        setSubCategory(parentId)
    }

    const hideSubCategory = () => {
        setSubCategory('')
    }


    const TopCategory = categories.filter(category => !category.parent).map(category => {
        return (
            <Link to={`/category/${category.slug}`}>
                <span
                    className=''
                    style={ spanStyle }
                    onMouseOver={() => showSubCategory(category.id)}
                    // onMouseLeave={() => showSubCategory()}
                >{ category.name }</span>
            </Link>
        )        
    })


    const SubCategory = categories.map(category => {

        if (category.parent !== subCategory || !category.parent) { return null }

        return (
            <Link to={{pathname : `/category/${category.slug}`, state: {catgId: category.id}}} >
                <span className='' style={spanStyle} >{category.name}</span>
            </Link>
        )
    })


    return (
        <div id='HeaderLeft' onMouseLeave={hideSubCategory}>
            {TopCategory}
            <br />
            {SubCategory}
        </div>
    )
}


export default HeaderLeft