// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { sendInformation } from '../../helper/crudHandler'


export const finalizeCheckout = async orderId => {
    return await sendInformation('/mustom-bank-transfer/checkout', {
        orderId: orderId
    })
}


