// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { readData } from '../../helper/crudHandler'


export const readProductSearchResult = async keyword => {
    return await readData(`/search/product/${keyword}`)
}

export const readPageSearchResult = async keyword => {
    return await readData(`/search/page/${keyword}`)
}
