// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { updatePassword } from '../../api/auth/resetPassword'
import showNotice from '../../helper/noticeDialog'


const UpdatePassword = props => {

    const navigate = useNavigate()
    const [ password, setPassword ] = useState('')
    const [ repeat, setRepeat ] = useState('')

    const sendPassword = async () => {
        if (!password || !repeat) {
            showNotice('Please enter require field(s).')
            return
        }

        if (password !== repeat) {
            showNotice('Password and confirm password must match!')
            return
        }

        const dataToSend = {
            password: password,
            key: props.secret
        }
        
        const updateRequest = await updatePassword(dataToSend)
            if (updateRequest.result === 'success') {
                showNotice('Your password has been changed successfully!')
                navigate('/')
            } else {
                showNotice('Something went wrong. Please try again later.')
            }

        setPassword('')
        setRepeat('')
    }


    return (
        <React.Fragment>
            <div>
                <h3>Please enter your new password here.</h3>
                <p>New Password</p>
                <input name='password' type='password' value={password} onChange={event => setPassword(event.target.value)} />
                <p>Confirm Password</p>
                <input name='repeat' type='password' value={repeat} onChange={event => setRepeat(event.target.value)} />
            </div>
            <div style={{marginTop: '100px'}}>
                <span className='FixedWidthBtn' onClick={sendPassword}>Reset Password</span>
            </div>
        </React.Fragment>
    )
}

export default UpdatePassword