// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readCountryAllowed} from '../../api/common/address'


const Country = props => {

    const [ countries, setCountries ] = useState([])

    useEffect(() => {
        getAllowedCountry()
    }, [])


    const getAllowedCountry = async () => {

        const fetchCountries = await readCountryAllowed()
        
        if (fetchCountries.result === 'success' && Array.isArray(fetchCountries.info)) {
            setCountries(fetchCountries.info)
        }
    }

    const onChangeHandler = event => {
        const countryCode = event.target.value
        const countryName = event.target.options[event.target.selectedIndex].text
        props.action(countryCode, countryName)
    }


    const Option = countries.map(country => {
        return (
            <option
                value={country.code}
                selected={props.selected?.includes(country.code) ? true : false}
            >
            {country.name}
            </option>
        )
    })

    return (
        <React.Fragment>
            <select
                name={props.id}
                id={props.id}
                onChange={onChangeHandler}
            >
                <option value='' hidden>Please Select Country</option>
                { Option }
            </select>
        </React.Fragment>
    )
}


export default Country


