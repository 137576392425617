// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { readData } from '../../helper/crudHandler'


export const readRateByProductId = async productId => {
    return await readData(`/rate/product-id/${productId}`)
}
