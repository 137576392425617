// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { validateClosureKey } from '../../api/auth/closureAccount'


const AccountClosureVerification = props => {

    const [ isValid, toggleValidity ] = useState(false)
    const { key } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        verifyClosureKey()
    }, [])

    const verifyClosureKey = async () => {
        const verifyRequest = await validateClosureKey(key)
        toggleValidity(verifyRequest.result === 'success' ? true : false)
    }

    const SuccessBlock = () => {
        return (
            <React.Fragment>
                <div>
                    <h3>Your account has been deleted successfully!</h3>
                    <h3>Hope to see you again.</h3>
                </div>
                <div style={{marginTop: '100px'}}>
                    <span className='FixedWidthBtn' onClick={() => navigate('/')}>Back to Main</span>
                </div>
            </React.Fragment>
        )
    }

    const ExpiredBlock = () => {
        return (
            <div>
                <h3>The confirmation link that you received has expired, or wrong.</h3>
                <h3>Please try again.</h3>
            </div>
        )
    }
        
    return (
        <React.Fragment>
            <h1 className='PageTitle2'>Account Closure Confirmation</h1>
            <p className='SubtitleTxt'>We will miss you</p>
            <div className='RegGrayContainer' style={{marginBottom: '100px', textAlign: 'center'}}>
                { isValid ? <SuccessBlock /> : <ExpiredBlock /> }
            </div>
        </React.Fragment>
    )
}


export default AccountClosureVerification












