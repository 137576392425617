// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readAvailableShippingMethod, executeShippingService } from '../../api/service-registry/service'
import showNotice from '../../helper/noticeDialog'


const ShippingMethod = props => {

    const [ selected, toggleSelected ] = useState('')
    const [ availableMethods, setAvaiableMethods ] = useState([])


    useEffect(() => {

        if (props.shippingMethod && props.shippingMethod === selected) {
            toggleSelected(props.shippingMethod)
        }

        if (props.orderId && !availableMethods.length) {
            getAvailableShippingMethod(props.orderId)
        }
    }, [ props ])


    const getAvailableShippingMethod = async orderId => {
        const fetchAvailableMethod = await readAvailableShippingMethod(orderId)

        if (fetchAvailableMethod.result === 'service-not-found') {
            setAvaiableMethods([])
            return
        }

        if (fetchAvailableMethod.result !== 'success' || !fetchAvailableMethod.info) {
            showNotice('Sorry. Failed to retrieve shipping method data. Please try again later.')
            return
        }

        setAvaiableMethods(fetchAvailableMethod.info)
    }

    const updateShippingMethod = async event => {
        
        toggleSelected(event.target.id)

        const updateRequest = await executeShippingService(event.target.id, props.orderId)

        if (updateRequest.result !== 'success') {
            toggleSelected('')
            showNotice('Failed to update information. Please try again.')
            return
        } else {
            props.updateCallback()
        }
    }

    const ShippingMethodList = availableMethods.map((method, index) => {
        return (
            <div style={{marginBottom: '10px'}}>
                <label>
                    <input
                        type='radio'
                        id={method.serviceCode}
                        checked={selected === method.serviceCode ? true : false }
                        onChange={updateShippingMethod}
                    />
                    <span style={{fontWeight: 500, verticalAlign: 'middle'}}>{method.title}</span>
                    <span style={{marginLeft: '14px', verticalAlign: 'middle'}}>- ${method.shippingFee}</span>
                </label>
                <p style={{marginLeft: '18px'}}>
                {method.introduction}
                </p>
            </div>
        )
    })

    const NoMethodAvailable = () => {
        return (
            <div>
                <span>Sorry. There are no shipping method available for your order.</span>
            </div>
        )
    }

    return (
        <div>
            <span className='CheckoutMenu'>Shipping Method</span>
            <div className='CheckoutMenuContent'>
                { !availableMethods.length ? <NoMethodAvailable /> : ShippingMethodList }
            </div>
        </div>
    )
}


export default ShippingMethod



