// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { readCustomerById } from '../../../api/customer/customer'
import { signOut } from '../../../api/auth/signOut'
import LoginContext from '../../../context/LoginContext'
import showNotice from '../../../helper/noticeDialog'


const HeaderTop = props => {

    const user = useContext(LoginContext)
    const [ customer, setCustomer ] = useState({})

    useEffect(() => {
        getData(user.customerId)
    }, [ user ])


    const getData = async customerId => {

        if (!customerId) { return }

        const fetchCustomer = await readCustomerById(customerId)

        if (fetchCustomer.result !== 'success' || !fetchCustomer.info) {
            showNotice('Failed to retrieve account data.')
            return
        }

        setCustomer(fetchCustomer.info)
    }


    const logOut = async () => {
        const logOutRequest = await signOut()

        if (logOutRequest.result !== 'success') {
            showNotice('Something went wrong.')
            return
        }

        user.updateStatus()
        showNotice('You have successfully logged out.')
    }


    const SignSelector = () => {
        if (user.customerId) {
            return(
                <React.Fragment>
                    <Link to='/account'>
                        <span>
                            {`My Account (${customer.firstName || customer.lastName ? `${customer.firstName} ${customer.lastName}` : customer.username})`}
                        </span>
                    </Link>
                    <span onClick={() => logOut()}>
                        Sign Out
                    </span>
                </React.Fragment>
            )
        } else {
            return(
                <Link to='/sign'>
                    <span>Sign In</span>                
                </Link>
            )
        }  
    }


    return (
        <div id='HeaderTop'>
            <div id='HeaderTopLeft'>
                <span>Welcome to MUSTOM Demo Store</span>
            </div>
            <div id='HeaderTopRight'>
                <span>Help Center</span>
                <SignSelector />
            </div>
        </div>
    )
}


export default HeaderTop
