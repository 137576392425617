// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { readProductSearchResult } from '../../api/search/search'
import ItemBox from '../partials/ItemBox'

const ProductSearch = props => {

    const [ result, setResult ] = useState([])
    const { keyword } = useParams()

    useEffect(() => {
        getResult(keyword)
    }, [ keyword ])


    const getResult = async keyword => {

        const fetchResult = await readProductSearchResult(keyword)

        if (fetchResult.result === 'success' && fetchResult.info?.length) {
            setResult(fetchResult.info)
        } else {
            setResult([])
        }
    }

    const SearchedItems = result.map(item => {
        return (
            <ItemBox
                slug={item.slug}
                name={item.name}
                image={item.imageSmall}
                price={item.regularPrice}
            />
        )
    })

    const NoRecords = () => {
        return (
            <div style={{margin: '50px 0', textAlign: 'center'}}>
                <p>No results for {keyword}.</p>
                <p>Try checking your spelling or use more general terms</p>
            </div>
        )
    }
    
    return (
        <div>
            {!result.length ? <NoRecords /> : SearchedItems}
        </div>
    )
}


export default ProductSearch
    


