// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'


const NoMatch = () => {

    const containerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: 'center'
    }

    return (
        <div style={{height: '80vh'}}>
            <div style={containerStyle}>
                <p>THE PAGE YOU REQUESTED WAS NOT FOUND!</p>
                <p>PLEASE GO BACK!</p>
            </div>
        </div>
    )
}


export default NoMatch


