// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { createData, readData, readDataAll, updateData, deleteData, sendInformation } from '../../helper/crudHandler'


export const sendResetRequest = async data => {
    return await sendInformation('/auth/customer/password/send', data)
}


export const validateKey = async data => {
    return await sendInformation('/auth/customer/password/validate', data)
}


export const updatePassword = async data => {
    return await sendInformation('/auth/customer/password/update', data)
}