// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readWishListByCustomerId, deleteWishList } from '../../api/wish-list/wish-list'
import Item from './blocks/Item'
import showNotice from '../../helper/noticeDialog'


const WishList = props => {

    const [ items, setItems ] = useState([])

    useEffect(() => {
        getWishList(props.customerId)
    }, [ props.customerId ])

    const getWishList = async customerId => {

        const fetchWishLists = await readWishListByCustomerId(customerId)
        if (![ 'success', 'no-record' ].includes(fetchWishLists.result) || !fetchWishLists.info) {
            showNotice('Failed to retrieve wish list data. Please try again later.')
            return
        }

        setItems(fetchWishLists.info)
    }

    const removeWishList = async index => {

        const itemToRemove = items[index].id

        const deleteRequest = await deleteWishList(itemToRemove)

        if (deleteRequest.result !== 'success') {
            showNotice('Failed to delete wish list. Please try again later.')
            return
        }

        getWishList(props.customerId)
    }

    const WishList = items.map((item, index) => {
        return (
            <Item
                customerId={props.customerId}
                productId={item.productId}
                option={item.option}
                removeItem={() => removeWishList(index)}
            />
        )
    })

    const NoRecord = () => {
        return (
            <div>
                <p>You don't have any items in your wish list.</p>
            </div>
        )
    }

    return (
        <div className='AccountContent'>
            <p className='AccountSectionMenu'>Items in your wishlist</p>
            {items.length ? WishList : <NoRecord />}
        </div>
    )
}


export default WishList