// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import { Link } from 'react-router-dom'
import { getProductImageUrl } from '../../helper/getProductImageUrl'
import { formatCurrency } from '../../helper/currencyFormatter'
import './ItemBox.css'


const ItemBox = props => {
    return (
        <Link to={'/product/' + props.slug}>
            <div className='ItemBoxContainer'>
                <div className='ItemBoxImg'>
                    <img src={getProductImageUrl(props.image)} alt='Product' />
                </div>
                <div className='ItemBoxTxt'>
                    <p>{props.name}</p>
                    <p className='ItemBoxPrice'>{formatCurrency(props.price)}</p> 
                    <div className='ItemBoxBottom'>
                        <i className='material-icons'>assignment_returned</i>
                        <i className='material-icons'>favorite</i>
                    </div>    
                </div>
            </div>
        </Link>
    )
}


export default ItemBox



