// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    createData,
    readData,
    readDataAll,
    updateData,
    deleteData,
    sendInformation
} from '../../helper/crudHandler'


export const signOut = async () => {
    return await sendInformation('/auth/customer/sign-out', {})
}