// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readReviewByCustomerId, deleteReviewById } from '../../api/review/review'
import showNotice from '../../helper/noticeDialog'
import showAlert from '../../helper/alertDialog'


const Review = props => {

    const [ reviews, setReviews ] = useState([])

    useEffect(() => {
        getData(props.customerId)
    }, [ props.customerId ])


    const getData = async customerId => {
        const fetchReviews = await readReviewByCustomerId(customerId)
        if (fetchReviews.result !== 'success' || !fetchReviews.info) {
            setReviews([])
            return
        }

        setReviews(fetchReviews.info)
    }

    const deleteReview = async reviewId => {

        const alert = await showAlert(
            'Are you sure you want to delete this review?',
            'Yes',
            'No'
        )

        if (alert === 'two') { return }

        const deleteReqeust = await deleteReviewById(reviewId)
        showNotice(deleteReqeust.result === 'success' ? 'Failed to delete review. Please try again later.' : 'Your review has been deleted.')
    } 

    const ReviewList = reviews.map(review => {
        return (
            <React.Fragment>
                <tr>
                    <td>{review.id}</td>
                    <td>{review.productName}</td>
                    <td>{review.title}</td>
                    <td>{review.rate}</td>
                    <td>{review.createdAt}</td>
                    <td>
                        {/* <span className='RegTxtBtn' onClick={() => editReview(review.id)}>EDIT</span> */}
                        <span className='RegTxtBtn' onClick={() => deleteReview(review.id)}>DELETE</span>
                    </td>
                </tr>
            </React.Fragment>
        )
    })

    const TableNoResult = () => {
        return (             
            <tr className='TableNoResult'>
                <td colSpan='6'>You don't have any reviews yet!</td>
            </tr>
        )
    }

    return (
        <div className='AccountContent'>
            <p className='AccountSectionMenu'>Your Reviews</p>
            <table width='100%' className='InnerTable'>
                <thead>
                    <tr>
                        <th width='15%'>ID</th>
                        <th width='15%'>Reviewd Prduct</th>
                        <th width='10%'>Review Title</th>
                        <th width='30%'>Rate</th>
                        <th width='15%'>Posted At</th>
                        <th width='15%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    { !reviews.length ? <TableNoResult /> : ReviewList }                   
                </tbody>
            </table>    
        </div>
    )
}


export default Review


