// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { validateKey, resendEmail } from '../../api/auth/confirmEmail'
import SuccessBlock from './SuccessBlock'
import ResendBlock from './ResendBlock'
import showNotice from '../../helper/noticeDialog'


const EmailVerification = props => {

    const [ isValid, toggleValidity ] = useState(false)
    const { key } = useParams()

    useEffect(() => {
        checkEmailKey()
    }, [])

    const checkEmailKey = async () => {
        const checkValidity = await validateKey({ key: key })

        if (checkValidity.result === 'success') {
            toggleValidity(true)
        } else if (checkValidity.result === 'key-not-exist') {
            toggleValidity(false)
        } else {
            showNotice('Something went wrong. Please try again later.')
        }
    }
        
    return (
        <React.Fragment>
            <h1 className='PageTitle2'>Varify Your Email</h1>
            <p className='SubtitleTxt'>Activate your account</p>
            <div className='RegGrayContainer' style={{marginBottom: '100px', textAlign: 'center'}}>
                { isValid ? <SuccessBlock /> : <ResendBlock /> }
            </div>
        </React.Fragment>
    )
}


export default EmailVerification












