// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { readAllServices } from '../api/service-registry/service'


export const getServiceName = async code => {
    try {
        const fetchServices = await readAllServices()
        const filtered = fetchServices.info.filter(service => service.serviceCode === code)
        if (!filtered.length) {
            return ''
        }
        const serviceName = filtered[0].name
        return serviceName
    } catch (err) {
        return ''
    }
}

  
