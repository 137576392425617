// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readReviewByProuctId, createReview } from '../../../api/review/review'
import { convertToDate } from '../../../helper/dateConverter'
import { getConfig } from '../../../helper/getConfig'
import showNotice from '../../../helper/noticeDialog'


const ReviewList = props => {

    const [ reviews, setReview ] = useState([])
    const [ noRecordMessage, setMessage ] = useState('Be the first to review this product')

    useEffect(() => {
        getReview(props.productId)
    }, [ props.productId ])


    const getReview = async productId => {

        if (!productId) { return }

        const fetchReviews = await readReviewByProuctId(productId)

        if (fetchReviews.result === 'success') {
            setReview(fetchReviews.info)
            return
        }

        if (fetchReviews.result === 'no-record') {
            setMessage('Be the first to review this product')
            return
        }
        
        setMessage('Failed to retrieve product review.')
    }

    const NoReview = () => {
        return (
            <div id='NoReview'>
                <span>{noRecordMessage}</span>
            </div>
        )
    }

    const Star = rate => {
        return (
            <React.Fragment>
                <span className={rate >= 1 ? 'StarFilled' : 'StarEmpty'} style={{fontSize: '20px'}}>&#128970;</span>
                <span className={rate >= 2 ? 'StarFilled' : 'StarEmpty'} style={{fontSize: '20px'}}>&#128970;</span>
                <span className={rate >= 3 ? 'StarFilled' : 'StarEmpty'} style={{fontSize: '20px'}}>&#128970;</span>
                <span className={rate >= 4 ? 'StarFilled' : 'StarEmpty'} style={{fontSize: '20px'}}>&#128970;</span>
                <span className={rate === 5 ? 'StarFilled' : 'StarEmpty'} style={{fontSize: '20px'}}>&#128970;</span>
            </React.Fragment>
        )
        
    }

    const ReviewList = reviews.map(data => {
        return (
            <div className='ReviewBox'>
                <div className='ReviewContent'>
                    {Star(data.rate)}
                    <span>{data.customerName}</span>
                </div>
                <div className='ReviewContent'>
                    <span>{convertToDate(data.createdAt)}</span>
                    <span style={{fontWeight: 'bold'}}>{data.title}</span>
                </div>
                <div className='ReviewContent'>
                    <p>{data.review}</p>
                </div>
            </div>
        )
    })


    return (
        <div id='ReviewRight'>
            { reviews.length <= 0 ? <NoReview /> : ReviewList }
        </div>
    )
}


export default ReviewList
