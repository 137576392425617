// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    createData,
    readManyData,
    deleteData
} from '../../helper/crudHandler'


export const createWishList = async data => {
    return await createData('/wish-list', data)
}

export const readWishListByCustomerId = async customerId => {
    return await readManyData(`/wish-list/customer-id/${customerId}`)
}

export const deleteWishList = async wishListId => {
    return await deleteData(`/wish-list/id/${wishListId}`)
}