// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import NameRegistration from './blocks/NameRegistration'
import {
    readCustomerById,
    updateCustomer,
    deleteCustomer
} from '../../api/customer/customer'
import { requestAccountClousure } from '../../api/auth/closureAccount'
import { getConfig } from '../../helper/getConfig'
import showNotice from '../../helper/noticeDialog'


const Profile = props => {

    const initialData = {
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        emailSubscription: false
    }

    const [ profile, setProfile ] = useState(initialData)
    const [ shouldOpenNameInput, toggleNameInput ] = useState(false)
    const isClosureNeedConfirmation = getConfig('account_closure_need_confirmation')
    
    useEffect(() => {
        getData(props.customerId)
    }, [])

    const getData = async customerId => {

        const fetchCustomer = await readCustomerById(customerId)
            if (fetchCustomer.result !== 'success' || !fetchCustomer.info) {
                showNotice('Something went wrong. Please try again later.')
                return
            }

            setProfile(fetchCustomer.info)
    }

    const onChangeHandler = async event => {
        setProfile(prevData => {
            return { ...prevData, [event.target.name]: event.target.value }
        })

        await updateCustomer(props.customerId, {
            emailSubscription: event.target.value ? true: false
        })

        getData(props.customerId)
    }


    const closeNameRegistration = () => {
        getData(props.customerId)
        toggleNameInput(false)
    }

    const deleteAccount = async () => {

        const deleteRequest = await requestAccountClousure({
            customerId: props.customerId,
            userName: profile.userName
        })

        if (deleteRequest.result === 'unresolved-task-exist') {
            showNotice('You have orders, or dispute in progess.')
            return
        }

        showNotice(deleteRequest.result === 'success' ?
            `Your account closure request has been requested. ${isClosureNeedConfirmation === 'yes' ? 'You will get an confirmation email.' : null}`
            : 'Something went wrong. Pleasre try again later.'
        )
    }

    return (
        <React.Fragment>
            { shouldOpenNameInput ? <NameRegistration customerId={props.customerId} closeCallback={closeNameRegistration} /> : null}
            <div className='AccountContent'>
                <div className='AccountSection'>
                    <p className='AccountSectionMenu'>Your Information</p>
                    <div style={{position: 'relative', marginBottom: '30px'}}>
                        <p>First Name : {profile.firstName}</p>
                        <p>Last Name : {profile.lastName}</p>
                        <p>Email : {profile.email}</p>
                        <span
                            className='SmallTextButton'
                            style={{position: 'absolute', top: '0', right: '0'}}
                            onClick={() => toggleNameInput(true)}
                        >
                            {!profile.firstName && !profile.lastName ? 'Register My Name' : 'Change My Name'}
                        </span>
                    </div>
                </div>
                <div className='AccountSection'>
                    <p className='AccountSectionMenu'>Newsletters</p>
                    <p style={{marginBottom: '20px'}}>Stay updated! Subscribe to our newsletter with the latest news about exclusive products and special offers.</p>
                    <input
                        type='checkbox'
                        name='emailSubscription'
                        style={{verticalAlign: 'middle'}}
                        value={profile.emailSubscription}
                        checked={profile.emailSubscription ? true : false}
                        onChange={onChangeHandler}
                    />
                    <span style={{paddingLeft : '10px', verticalAlign : 'middle'}}>I would like to receive newsletters</span>
                </div>
                <div className='AccountSection'>
                    <p className='AccountSectionMenu'>Danger Zone</p>
                    <span
                        className='SmallTextButton'
                        onClick={deleteAccount}    
                    >Delete My Account</span>
                    <div style={{marginTop: '10px'}}>
                        <p className='GuideText'>Once account has been deleted, it can not reverted.</p>
                        <p className='GuideText'>If you have orders, or dispute in progress, request account deletion after it is in completed status.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default Profile



