// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'

import { resendConfirmationEmail } from '../../api/auth/confirmEmail'
import showNotice from '../../helper/noticeDialog'


const ResendBlock = () => {

    const [ email, setEmail ] = useState('')


    const resendEmail = async () => {
        if(!email) {
            showNotice('Please enter email address.')
        }

        // If you don't use 'email as user name' config, change this line (state email  ===> state userName)
        const dataToSend = { userName: email }

        const resendRequest = await resendConfirmationEmail(dataToSend)
        
        showNotice(resendRequest.result === 'success' ?
        `We sent an email to ${this.state.email}! Please check your inbox and find confirmation email we've sent you.`
        :
        'Something went wrong. Please try again later.'
        )
    }

    return (
        <React.Fragment>
            <div>
                <h3>The activation link that you received has expired, or wrong.</h3>
                <h3>Please input your email and click 'Resend' button.</h3>
                <input
                    name='email'
                    type='email'
                    placeholder = 'e.g. john-doe@domain.com'
                    style={{ width : '500px', marginTop: '100px' }}
                    value={email}
                    onChange={event => setEmail(event.value)}
                />
            </div>
            <div style={{marginTop: '100px'}}>
                <span className='FixedWidthBtn' onClick={resendEmail}>Resend</span>
            </div>
        </React.Fragment>
    )
}


export default ResendBlock












