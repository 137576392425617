// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import axios from 'axios'


const api = axios.create({
    baseURL: process.env.REACT_APP_ENGINE_API,
    timeout: 2500,
    withCredentials: process.env.REACT_APP_ENV === 'development' ? true : false,
    headers: {'requestor': 'customer'}
})

const spinnerControl = state => {
    const spinner = document.getElementById('spinner')
    if (spinner) {
        spinner.style.display = state === 'on' ? 'block' : 'none'
    }
}

api.interceptors.request.use(
    config => {
        spinnerControl('on')
        return config
    }, 
    error => {
        spinnerControl('off')
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    response => {
        spinnerControl('off')
        return response
    },
    error => {
        spinnerControl('off')
        return Promise.reject(error)
    }
)

export const createData = async (path, data) => {
    try {
        const response = await api.post(path, data)
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : null,
            message: response.data.result === 'success'? 'Create data successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: null,
            message: errorHandler.message
        }

        return resultToReturn
    }
}

export const readManyData = async path => {
    try {
        const response = await api.get(path)
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : [],
            message: response.data.result === 'success'? 'Get data successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: [],
            message: errorHandler.message
        }

        return resultToReturn
    }
}

export const readData = async path => {
    try {
        const response = await api.get(path)
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : {},
            message: response.data.result === 'success'? 'Get data successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: {},
            message: errorHandler.message
        }

        return resultToReturn
    }
}

export const updateData = async (path, data) => {
    try {
        const response = await api.put(path, data)
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : null,
            message: response.data.result === 'success'? 'Update data successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: null,
            message: errorHandler.message
        }

        return resultToReturn
    }
}

export const deleteData = async path => {
    try {
        const response = await api.delete(path)
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : null,
            message: response.data.result === 'success'? 'Delete data successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: null,
            message: errorHandler.message
        }
        
        return resultToReturn
    }
}

export const sendInformation = async (path, data) => {
    try {
        const response = await api.post(path, data)
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : null,
            message: response.data.result === 'success'? 'Send information successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: null,
            message: errorHandler.message
        }

        return resultToReturn
    }
}

// Controllers mostly for grid pages
export const readDataList = async (path, option) => {
    try {
        const response = await api.get(process.env.REACT_APP_ENGINE_API + path, { params: option })
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : [],
            count: response.data.result === 'success' ? response.data.count || response.data.info.length : 0,
            message: response.data.result === 'success'? 'Get grid data successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: [],
            count: 0,
            message: errorHandler.message
        }

        return resultToReturn
    }
}

export const deleteManyData = async (path, id) => {
    try {
        const response = await api.delete(process.env.REACT_APP_ENGINE_API + path, { data: { id: id } })
        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : null,
            message: response.data.result === 'success'? 'Delete data successfully!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: null,
            message: errorHandler.message
        }

        return resultToReturn
    }
}

// Send Single File/Image
export const fileSender = async (condition, file) => {
    try {
        const response = await axios.post(process.env.REACT_APP_ENGINE_API + condition.path, file, {
            timeout: condition.timeout || 5000,
            headers: {
                'Content-Type': 'multipart/form-data',
                'requestor': 'admin'
            }
        })

        const resultToReturn = {
            result: response.data.result,
            info: response.data.result === 'success' ? response.data.info : null,
            message: response.data.result === 'success'? 'Success!' : 'Something went wrong!'
        }

        return resultToReturn

    } catch (error) {
        const errorHandler = handleError(error)
        const resultToReturn = {
            result: errorHandler.result,
            info: null,
            message: errorHandler.message
        }

        return resultToReturn
    }
}

function handleError(error) {

    if (error.response) {

        // If it is maintenance mode, force redirect to under maintenance page
        if (error.response.status === 503 && error.response.data.result === 'under-maintenance') {
            const pathName = window.location.pathname
            if (!pathName.includes('under-maintenance')) {
                window.location.replace(`/under-maintenance`)
            }
        } 
        
        return {
            result: error.response.data.result || 'error',
            message: error.response.data.message || 'Under maintenance'
        }
    } else if (error.request) {
        
        // The request was made but no response was received
        return {
            result: error.name || 'no-response',
            message: error.message || 'Something went wrong.'
        }
    } else {
        return {
            result: error.name || 'error',
            message: error.message || 'Something went wrong.'
        }
    }
}