// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readAddressByCustomerId } from '../../api/customer/address'
import showNotice from '../../helper/noticeDialog'
import AddressBlock from './blocks/AddressBlock'


const AddressBook = props => {

    const [ address, setAddress ] = useState([])

    useEffect(() => {
        getAddresses(props.customerId)
    }, [])


    const getAddresses = async customerId => {

        const fetchAddresses = await readAddressByCustomerId(customerId)
        if (fetchAddresses.result !== 'success' || !fetchAddresses.info) {
            showNotice('Failed to retrieve address data. Please try again later.')
            return
        }

        setAddress(fetchAddresses.info)
    }

    const NoRecord = () => {
        return (
            <div>
                <p>You don't have any address in your address book.</p>
            </div>
        )
    }

    const AddressList = address.map(address => {
        return (
            <AddressBlock address={address} />
        )
    })

    return (
        <div className='AccountContent' style={{textAlign: 'center'}}>
            <p className='AccountSectionMenu'>Store your address for later use</p>
            {!address.length ? <NoRecord /> : AddressList}
        </div>
    )
}


export default AddressBook




