// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    readManyData,
    sendInformation
} from '../../helper/crudHandler'


export const readAllServices = async () => {
    return await readManyData(`/service/all`)
}

export const readAvailableShippingMethod = async orderId => {
    return await readManyData(`/service/available-shipping-method/${orderId}`)
}

export const readAvailablePaymentMethod = async orderId => {
    return await readManyData(`/service/available-payment-method/${orderId}`)
}

export const executeShippingService = async (serviceCode, orderId) => {

    const dataToSend = {
        serviceCode: serviceCode,
        orderId: orderId
    }

    return await sendInformation('/service/execute-shipping-method', dataToSend)
}

export const executePaymentService = async (serviceCode, orderId) => {

    const dataToSend = {
        serviceCode: serviceCode,
        orderId: orderId
    }

    return await sendInformation('/service/execute-payment-method', dataToSend)
}