// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { validateKey } from '../../api/auth/resetPassword'
import UpdatePassword from './UpdatePassword'
import DeadLink from './DeadLink'


const ResetPassword = props => {

    const { key } = useParams()
    const [ isValidLink, setValidationResult ] = useState(true)

    useEffect(() => {
        checkResetKey(key)
    }, [])

    const checkResetKey = async resetKey => {
        const validationRequest = await validateKey({ key: resetKey })
        if (validationRequest.result !== 'success') {
            setValidationResult(false)
        } 
    }

    return (
        <React.Fragment>
            <h1 className='PageTitle2'>Reset Your Password</h1>
            <p className='SubtitleTxt'>Enter a new password for your account.</p>
            <div className='RegGrayContainer' style={{marginBottom: '100px', textAlign : 'center'}}>
                { isValidLink ? <UpdatePassword secret={key} /> : <DeadLink />}
            </div>
        </React.Fragment>
    )
}

export default ResetPassword












