// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Dashboard from './Dashboard'
import Profile from './Profile'
import AddressBook from './AddressBook'
import Order from './Order'
import Review from './Review'
import WishList from './WishList'
import ViewedItem from './ViewedItem'
import LoginContext from '../../context/LoginContext'
import './style.css'


const Account = props => {

    const user = useContext(LoginContext)
    const [ menu, setMenu ] = useState('dashboard')
    const navigate = useNavigate()

    useEffect(() => {

        // Prevent guest access
        if (!user.customerId) {
            navigate(-1)
        }
    }, [])

    return (
        <React.Fragment>
            <h1 className='PageTitle2'>MY ACCOUNT</h1>
            <p className='SubtitleTxt'>Phasellus at turpis sem. Integer a eros vel massa porttitor euismod quis vel eu.</p>                  
            <div className='RegGrayContainer' style={{marginBottom: '50px'}}>
                <div id='AccountMenu'>
                    <ul>
                        <li onClick={() => setMenu('dashboard')}>Dashboard</li>
                        <li onClick={() => setMenu('profile')}>Profile</li>
                        <li onClick={() => setMenu('addressBook')}>Address Book</li>
                        <li onClick={() => setMenu('order')}>Orders</li>
                        <li onClick={() => setMenu('order')}>Disputes</li>
                        <li onClick={() => setMenu('review')}>Reviews</li>
                        <li onClick={() => setMenu('wishList')}>Wish List</li>
                        <li onClick={() => setMenu('viewed')}>Recently Viewed Items</li>
                    </ul>
                </div>
                    {menu === 'dashboard' ? <Dashboard customerId={user.customerId} /> : null}
                    {menu === 'profile' ? <Profile customerId={user.customerId} /> : null}
                    {menu === 'addressBook' ? <AddressBook customerId={user.customerId} /> : null}
                    {menu === 'order' ? <Order customerId={user.customerId} /> : null}
                    {menu === 'review' ? <Review customerId={user.customerId} /> : null}
                    {menu === 'wishList' ? <WishList customerId={user.customerId} /> : null}
                    {menu === 'viewed' ? <ViewedItem customerId={user.customerId} /> : null}
            </div>
        </React.Fragment>
    )
}


export default Account
