// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import Item from './blocks/Item'


const ViewedItem = props => {

    const [ items, setItem ] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        const viewedItem = localStorage.getItem('viewed-item')
        const ItemToArray = viewedItem?.split(',')
        if (viewedItem && ItemToArray.length) {
            setItem(ItemToArray)
        }
    }

    const removeViewedItem = index => {
        const newList = items.splice(index, 1)
        localStorage.setItem('viewed-item', newList.toString())
        getData()
    }
    
        
    const ItemList = items.map((data, index) => {
        return (
            <Item
                customerId={props.customerId}
                productId={data}
                removeItem={() => removeViewedItem(index)}
            />
        )
    })

    const NoRecord = () => {
        return (
            <div>
                <p>No records found.</p>
            </div>
        )
    }

    return (
        <div className='AccountContent'>
            <p className='AccountSectionMenu'>Viewed Items</p>
            {items.length ? ItemList : <NoRecord />}
        </div>
    )
}


export default ViewedItem

