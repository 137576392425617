// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import { Link } from 'react-router-dom'


const MobileMenu = props => {
    return (
        <div id='MobileMenu'>
            <div id='MobileMenuTop' onClick={() => props.closeMe()}>
                <p>CLOSE MENU</p>
            </div>
            <div id='MobileMenuBody'>
                <Link to='/'><h4 onClick={() => props.closeMe()}>Home</h4></Link>
            </div>
        </div>
    )
}


export default MobileMenu