// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


const leadZero = data => {
    return String(data).padStart(2, '0')
}


export const convertToDate = time => {
    if (!time) { return null }
    const date = new Date(time)
    return `${ date.getFullYear() }-${ leadZero(date.getMonth() + 1) }-${ leadZero(date.getDate()) }`
}


export const convertToDateTime = time => {
    if (!time) { return null }
    const date = new Date(time)
    return `${ date.getFullYear() }-${ leadZero(date.getMonth() + 1) }-${ leadZero(date.getDate()) } ${ leadZero(date.getHours()) }:${ leadZero(date.getMinutes()) }:${ leadZero(date.getSeconds()) }`
}
