// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'


const Maintenance = () => {

    const containerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: 'center'
    }

    return (
        <div style={{height: '80vh'}}>
            <div style={containerStyle}>
                <h4>UNDER MAINTENANCE</h4>
                <p>This site is currently under maintenance. We will be back on-line shortly.</p>
            </div>
        </div>
    )
}


export default Maintenance



