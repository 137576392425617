// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    sendInformation
} from '../../helper/crudHandler'


export const applyDiscount = async condition => {
    return await sendInformation('/discount-rule/application', condition)
}

export const cancelDiscount = async condition => {
    return await sendInformation('/discount-rule/cancellation', condition)
}