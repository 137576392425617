// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


const showNotice = message => {

    return new Promise(resolve => {

        const body = document.querySelector('body')
        const oldWrapper = document.querySelector('.NightWrapper')
        let background = oldWrapper ? oldWrapper : document.createElement('div')

        // Check if same notice is displayed
        let isDuplicated
        const existingMessage = document.querySelectorAll('.RegNoticeBox .message')
    
        for (const dialog of existingMessage) {
            if (dialog.innerText === message) {
                isDuplicated = true
            }
        }
    
        if (isDuplicated) { return }

        // Create Elements
        const box = document.createElement('div')
        const text = document.createElement('span')
        const buttonContainer = document.createElement('div')
        const button = document.createElement('span')

        // Set parent-child relation
        body.appendChild(background)
        background.appendChild(box)
        box.appendChild(text)
        box.appendChild(buttonContainer)
        buttonContainer.appendChild(button)

        // Add class
        background.className = 'NightWrapper'
        box.className = 'RegNoticeBox'
        text.className = 'message'
        buttonContainer.className = 'RegNoticeBottomOneButton'
        button.className = 'RegSmallBtn'

        // Add eventlistener
        button.addEventListener('click', buttonEvent)

        // Put Text
        text.innerText = message
        button.innerText = 'OK'

        // Button Event
        function buttonEvent() {
            if(oldWrapper) {
                oldWrapper.removeChild(box)
            } else {
                body.removeChild(background)
            }

            resolve()
        }
    })
}


export default showNotice