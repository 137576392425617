// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'


const FooterCopy = () => {
    return (
        <div id='FooterCopyBox'>
            <div className='RegContainer' style={{height: '30px'}}>
                <div id='FooterLeft'>
                    <span>©2023 Ryu Woosik. All Rights Reserved.</span>
                </div>
                <div id='FooterRight'>
                    <span>Terms & Conditions</span>
                    <span>Privacy Policy</span>
                </div>
            </div>
        </div>        
    )
}


export default FooterCopy