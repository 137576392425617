// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from './block/MobileMenu'
import Logo from './logo_black.svg'
import Menu from './menu.svg'


const HeaderMobile = () => {

    const [ isOpen, toggleMenu ] = useState(false)

    const menuContainer = {
        float: 'left',
        width: '40px',
        height: '40px',
        margin: '10px 0 10px 10px',
        padding: '10px'
    }

    const logoContainer = {
        margin: '0 auto',
        padding: '10px',
        textAlign: 'center',
        width: '60px',
        height: '100%'
    }

    return (
        <div id='MobileHeader'>
            { isOpen ? <MobileMenu closeMe={() => toggleMenu(!isOpen ? true : false)} /> : null }
            <div style={menuContainer} onClick={() => toggleMenu(!isOpen ? true : false)}>
                <img src={Menu} alt='store menu' />
            </div>
            <div style={logoContainer}>
                <Link to='/'><img src={Logo} alt='mustom demo store' /></Link>
            </div>
        </div>
    )
}


export default HeaderMobile