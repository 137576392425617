// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


const showInnerNotice = message => {

    return new Promise(resolve => {

        const body = document.querySelector('body')

        // Check if same notice is displayed
        let isDuplicated
        const existingMessage = document.querySelectorAll('.RegInnerNoticeBox .message')
    
        for (const dialog of existingMessage) {
            if (dialog.innerText === message) {
                isDuplicated = true
            }
        }
    
        if (isDuplicated) { return }

        // Create Elements
        const box = document.createElement('div')
        const text = document.createElement('span')
        const buttonContainer = document.createElement('div')
        const button = document.createElement('span')

        // Set parent-child relation
        body.appendChild(box)
        box.appendChild(text)
        box.appendChild(buttonContainer)
        buttonContainer.appendChild(button)

        // Add class
        box.className = 'RegInnerNoticeBox'
        text.className = 'message'
        buttonContainer.className = 'RegNoticeBottomOneButton'
        button.className = 'RegSmallBtn'

        // Add eventlistener
        button.addEventListener('click', buttonEvent)

        // Put Text
        text.innerText = message
        button.innerText = 'OK'

        // Button Event
        function buttonEvent() {
            body.removeChild(box)
            resolve()
        }
    })
}


export default showInnerNotice