// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { Suspense, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/header'
import Footer from './components/footer'
import Home from './components/home'
import EmailVerification from './components/email-confirmation'
import AccountClosureVerification from './components/closure-account-confirmation'
import ResetPassword from './components/reset-password'
import Sign from './components/sign'
import Account from './components/account'
import Cart from './components/cart'
import Checkout from './components/checkout'
import Category from './components/category'
import Product from './components/product'
import Page from './components/page'
import Search from './components/search'
import NoMatch from './components/misc/NoMatch'
import Maintenance from './components/misc/Maintenance'
import Spinner from './components/misc/Spinner'
import SuspenseSpinner from './components/misc/SuspenseSpinner'
import LoginContext from './context/LoginContext'
import { getConfigList } from './api/config-receiver/configReceiver'
import { verifyToken } from './api/auth/verifyToken'


const App = props => {

    const [ customerId, setCustomerId ] = useState(null)
    const [ isMobile, toggleMobile ] = useState(false)

    useEffect(() => {
        checkMobile()
        getConfigList()
        checkLogin()
    },[])
    
    
    const checkMobile = () => {
        const winWidth = window.innerWidth
        toggleMobile(winWidth <= 992 ? true : false)
    }
    
    const checkLogin = async () => {
        
        const tokenVerification = await verifyToken()
        
        if (tokenVerification.result === 'success' && tokenVerification.info) {
            setCustomerId(tokenVerification.info)
        } else {
            setCustomerId(null)
        }
    }

    return (
        <Router>
            <LoginContext.Provider value={{
                customerId: customerId,
                updateStatus: checkLogin
            }}
            >
                <Suspense fallback={<SuspenseSpinner />}>
                    <React.Fragment>
                        <Routes>
                            <Route path='/' element={<Header isHome isMobile={isMobile} /> } />
                            <Route path='/*' element={<Header isMobile={isMobile} /> } />
                        </Routes>
                        <Routes>                                      
                            <Route path='/' element={<Home />} />
                            <Route path='/sign' element={<Sign />} />
                            <Route path='/email-confirmation/:key' element={<EmailVerification />} />
                            <Route path='/close-account-confirmation/:key' element={<AccountClosureVerification />} />
                            <Route path='/reset-password/:key' element={<ResetPassword />} />
                            <Route path='/account' element={<Account />} />
                            <Route path='/cart' element={<Cart />} />
                            <Route path='/category/:slug' element={<Category />} />
                            <Route path='/product/:slug' element={<Product />} />
                            <Route path='/page/:slug' element={<Page />} />
                            <Route path='/search/:keyword' element={<Search />} />
                            <Route path='/checkout/buy-now/:key' element={<Checkout />} />
                            <Route path='/checkout/:key' element={<Checkout />} />
                            <Route path='/page-not-found' element={<NoMatch />} />
                            <Route path='/under-maintenance' element={<Maintenance />} />
                            <Route path='/*' element={<NoMatch />} />
                        </Routes>
                        <Routes>
                            <Route path='/*' element={<Footer />} />
                        </Routes>
                        <Spinner />
                    </React.Fragment>
                </Suspense>
            </LoginContext.Provider>
        </Router>
    )
}


export default App


