// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import { formatCurrency } from '../../../helper/currencyFormatter'


const Option = props => {

    const [ selected, setSelected ] = useState([])

    const onChangeHandler = event => {

        const justSelected = {
            id: event.target.value,
            optionName: event.target.name,
            valueName: event.target[event.target.selectedIndex].dataset.value,
            price: event.target[event.target.selectedIndex].dataset.price
        }

        if (!selected.length) {
            setSelected([ justSelected ])
            props.changeCallback([ justSelected ])
            return
        }

        const selectedCopy = [ ...selected ]

        for (const value of selectedCopy) {
            if (!value.optionName.includes(event.target.name)) {
                setSelected([ ...selectedCopy, justSelected ])
                props.changeCallback([ ...selectedCopy, justSelected ])
            } else {
                const targetPositon = selectedCopy.indexOf(value)
                selectedCopy.splice(targetPositon, 1)
                setSelected([ ...selectedCopy, justSelected ])
                props.changeCallback([ ...selectedCopy, justSelected ])
            }
        }
    }

    const ValueList = option => {

        const optionFilter = props.data.filter(data => data.option === option)

        return (optionFilter.map(data => {
            return (
                <option value={data.id} data-value={data.valueName} data-price={data.priceAdjustment} disabled={data.stockQty <= 0 ? true : false}>
                    {data.valueName}
                    {data.priceAdjustment !== 0 ? `  (${formatCurrency(data.priceAdjustment)})` : null}
                    {data.stockQty <= 0 ? '  -- Out of Stock' : null}
                </option>
            )
        }))
    }
    
    const options = [ ...new Map(props.data.map(item => [item['option'], item])).values() ]

    const OptionList = options.map(data => {
        return (
            <React.Fragment>
                <p>{data.option}</p>
                <select name={data.option} id={`option-${data.option}`} onChange={onChangeHandler}>
                    <option value='' hidden>Please Select Option</option>
                    {ValueList(data.option)}
                </select>
            </React.Fragment>
        )
    })

    return (
        <div id='OptionBox'>
            {OptionList}
        </div>
    )
}


export default Option