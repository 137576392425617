// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import BillingAddressInputBox from './blocks/BillingAddressInputBox'
import { updateOrderAddressByOrderId } from '../../api/order/order'
import showNotice from '../../helper/noticeDialog'


const BillingAddress = props => {

    const [ showInput, toggleInput ] = useState(false)
    const [ billTo, setBillTo ] = useState({})


    useEffect(() => {

        if (props.billingAddress) {
            setBillTo(props.billingAddress)
            return
        }

    }, [ props ])


    const copyShippingAddress = async () => {

        const shippingAddress = props.shippingAddress

        if (!shippingAddress) {
            showNotice('Please set shipping address first.')
            return
        }

        // Remove ID of shipping address, and change type 'shipping' to 'billing'
        const { id, ...rest } = { ...props.shippingAddress, type: 'billing' }
        const updateOrderAddressRequest = await updateOrderAddressByOrderId(props.orderId, rest)
        if (updateOrderAddressRequest.result !== 'success') {
            showNotice('Failed to update billing address. Please try again later.')
            return
        }
        
        props.updateCallback()
    }

    const AddressSelected = () => {
        return (
            <div id='CheckoutAddressContainer'>
                <div style={{marginBottom: '5px'}}>
                    <span>{billTo.firstName}</span>
                    <span>{billTo.firstName && billTo.lastName ? ' ' : null}</span>
                    <span>{billTo.lastName}</span>
                    {billTo.company ? <p>{billTo.company}</p> : null}
                </div>
                <div>
                    <span>{billTo.address1}</span>
                    <span>{billTo.address2 && billTo.address2 ? ', ' : null}</span>
                    <span>{billTo.address2}</span>
                    <span>{billTo.address3 ? ', ' : null}</span>
                    <span>{billTo.address3}</span>
                </div>
                <div>
                    <span>{billTo.city}</span>             
                    <span>{billTo.city && billTo.province ? ', ' : null}</span>
                    <span>{billTo.province}</span>
                    <span>{billTo.zip ? ', ' : null}</span>
                    <span>{billTo.zip}</span>
                    <span>{billTo.country ? ', ' : null}</span>
                    <span>{billTo.country}</span>
                </div>
                <div style={{marginTop: '5px'}}>
                    <span>{billTo.telephone1 ? 'Tel : ' : null}</span>
                    <span>{billTo.telephone1}</span>
                    <span>{billTo.telephone2 ? ', Tel2 : ' : null}</span>
                    <span>{billTo.telephone2}</span>
                </div>
            </div>
        )
    }

    const AddressNotExist = () => {
        return (
            <div>
                <div id='CheckoutAddressContainer' style={{textAlign: 'center'}}>
                    <span id='AddAddressButton' onClick={() => toggleInput(true)}>Add Address</span>
                </div>
                <p id='SameAsShippingButton' onClick={copyShippingAddress}>Set my billing address same as shipping address</p>
            </div>
        )
    }

    return (
        <React.Fragment>
            {showInput ?
                <BillingAddressInputBox
                    customerId={props.customerId}
                    orderId={props.orderId}
                    updateCallback={() => props.updateCallback()}
                    closeCallback={() => toggleInput(false)}
                /> :
                null
            }
            <div style={{marginTop: '50px'}}>
                <span className='CheckoutMenu'>Billing Address</span>
                <div className='CheckoutMenuContent'>
                    <p
                        className='CheckoutAddressChangeButton'
                        style={{display: billTo.createdAt === billTo.updatedAt ? 'none' : 'block'}}
                        onClick={() => toggleInput(true)}
                    >Change</p>
                    {billTo.createdAt === billTo.updatedAt ? <AddressNotExist /> : <AddressSelected />} 
                </div>
            </div>
        </React.Fragment>
    )
}


export default BillingAddress



