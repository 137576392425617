// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import { updateCustomer } from '../../../api/customer/customer'
import showInnerNotice from '../../../helper/innerNoticeDialog'


const NameRegistration = props => {

    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')

    const updateName = async () => {

        if (!firstName) { return }

        const updateReqeust = await updateCustomer(props.customerId, {
            firstName: firstName,
            lastName: lastName
        })

        if (updateReqeust.result !== 'success') {
            showInnerNotice('Failed to update your name. Please try again later.')
            return
        }

        props.closeCallback()
    }
   
    return (
        <div className='NightWrapper'>
            <div id='NameInput'>
                <div>
                    <h4 style={{textAlign: 'center', margin: '20px 0 60px 0'}}>Teach us your name!</h4>
                    <div className='NameInputRow'>
                        <span>First Name</span>
                        <input type='text' id='firstName' placeholder='First Name' value={firstName} onChange={event => setFirstName(event.target.value)} />
                    </div>
                    <div className='NameInputRow'>
                        <span>Last Name</span>
                        <input type='text' id='lastName' placeholder='Last Name' value={lastName} onChange={event => setLastName(event.target.value)} />
                    </div>
                    <div style={{textAlign: 'center', margin: '50px 0'}}>
                        <span
                            className='RegSmallBtnLight'
                            style={{marginRight: '5px'}}
                            onClick={() => props.closeCallback()}
                        >Cancel
                        </span>
                        <span
                            className='RegSmallBtn'
                            style={{opacity: firstName ? '1' : '0.5'}}
                            onClick={updateName}
                        >Save
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default NameRegistration



