// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    createData,
    readData,
    readManyData,
    updateData,
    deleteData
} from '../../helper/crudHandler'


export const readAddressByCustomerId = async customerId => {
    return await readManyData(`/address/customer-id/${customerId}`)
}

export const readAddressById = async addressId => {
    return await readData(`/address/id/${addressId}`)
}

export const createAddress = async data => {
    return await createData('/address', data)
}

export const updateAddress = async (addressId, data) => {
    return await updateData(`/address/id/${addressId}`, data)
}

export const deleteAddress = async addressId => {
    return await deleteData(`/address/id/${addressId}`)
}

