// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'


const Spinner = () => {
    return (
        <div id='spinner'>
            <div id='bounce1'></div>
            <div id='bounce2'></div>
            <div id='bounce3'></div>
        </div>
    )
}


export default Spinner