// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { readData } from '../../helper/crudHandler'


// Get static page data by slug
export const readPageBySlug = async slug => {
    return await readData(`/page/cache/slug/${slug}`)
}

// Get static page data by Page ID
export const readPageById = async pageId => {
    return await readData(`/page/cache/id/${pageId}`)
}