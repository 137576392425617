// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import Image from './catg_banner.jpg'


const CategoryBanner = () => {
    const Banner = {
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundImage: 'url(' + Image + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
    }

    return (
        <React.Fragment>
            <div id='CategoryBannerContainer' style={Banner}>
                <div id='CategoryText'>
                    <h1>Lorem ipsum dolor</h1>
                    <p>Vivamus a turpis tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus semper ullamcorper neque, placerat fringilla ipsum fringilla eget. Ut consequat ligula quis tortor varius, facilisis faucibus lectus fringilla.</p>
                    <br />
                    <p>● Phasellus tempus nulla, vel tincidunt felis condimentum a.</p>
                    <p>● Vestibulum viverra metus non metus scelerisque.</p>
                    <p>● Nullam aliquet urna at orci dapibus ornare.</p>
                    <p>● Suspendisse potenti. Donec auctor urna non luctus aliquet.</p>
                    <span className='CategoryBannerButton'>See more</span>
                </div>
            </div>
        </React.Fragment>        
    )
}

export default CategoryBanner