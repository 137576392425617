// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { readData } from '../../helper/crudHandler'
import showNotice from '../../helper/noticeDialog'


// Get configuration data from engine, and then store it into local storage
export const getConfigList = async () => {
    const fetchConfigs = await readData('/core-config/store')

    if (fetchConfigs.result !== 'success' || !fetchConfigs.info) {
        showNotice('Failed to retrieve store configuration.')
    } else {
        localStorage.setItem('coreConfig', JSON.stringify(fetchConfigs.info))
    }
}