// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readOrderByCustomerId } from '../../api/order/order'
import { addPreviosOrderItemToCart } from '../../api/cart/cart'
import { convertToDate } from '../../helper/dateConverter'
import { formatCurrency } from '../../helper/currencyFormatter'
import showNotice from '../../helper/noticeDialog'


const Order = props => {

    const [ orders, setOrders ] = useState([])
    const [ page, setPage ] = useState(1)

    useEffect(() => {
        getData(props.customerId)
    }, [])


    const getData = async customerId => {
        const fetchOrders = await readOrderByCustomerId(customerId)

            if (fetchOrders.result !== 'success' || !fetchOrders.info) {
                showNotice('Failed to retrieve order data. Please try again later.')
                return
            }

            setOrders(fetchOrders.info)
    }

    const viewDetail = () => {

        

    }

    const requestReorder = async orderId => {

        const request = await addPreviosOrderItemToCart(orderId)

        if (request.result === 'success') {
            showNotice('')
        }
    }


    const OrderHistory = orders.map(data => {
        return(
            <React.Fragment>
                <tr>
                    <td>{data.orderNumber}</td>
                    <td>{data.orderItem}</td>
                    <td>{convertToDate(data.createdAt)}</td>
                    <td>{data.orderStatus}</td>
                    <td>
                        <span className='RegTxtBtn' onClick={() => viewDetail(data.id)}>Detail</span>
                        <span> | </span>
                        <span className='RegTxtBtn' onClick={() => requestReorder(data.id)}>Reorder</span>
                    </td>
                </tr>
            </React.Fragment>
        )
    })

    const TableNoResult = props => {
        return (             
            <tr className='TableNoResult'>
                <td colSpan={props.colSpan}>You don't have any orders</td>
            </tr>
        )
    }
    
    return (
        <div className='AccountContent'>
            <p className='AccountSectionMenu'>Your orders in BerrySix</p>
            <table width='100%' className='InnerTable'>
                <thead>
                    <tr>
                        <th width='20%'>Order Number</th>
                        <th width='35%'>Order Item</th>
                        <th width='15%'>Order Date</th>
                        <th width='10%'>Status</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {!orders.length ? <TableNoResult colSpan = '6' /> : OrderHistory}                   
                </tbody>
            </table>    
        </div>
    )
}


export default Order


