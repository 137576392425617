// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    readOrderById,
    readOrderItemByOrderId,
    readOrderAddressByOrderId
} from '../../api/order/order'
import { getProductImageUrl } from '../../helper/getProductImageUrl'
import { formatCurrency } from '../../helper/currencyFormatter'
import { getServiceName } from '../../helper/getServiceName'
import showNotice from '../../helper/noticeDialog'

// You can create individual route for order result page.
const OrderResult = props => {

    const navigate = useNavigate()
    const [ order, setOrder ] = useState({})
    const [ orderItems, setOrderItems ] = useState([])
    const [ shippingAddress, setShippingAddress ] = useState({})
    const [ billingAddress, setBillingAddress ] = useState({})
    const [ isAbnormal, setAbnormal ] = useState(false)
    
    useEffect(() => {
        window.scrollTo(0, 0)
        getOrder(props.orderId)
        getOrderItem(props.orderId)
        getOrderAddress(props.orderId)
    }, [])


    const getOrder = async orderId => {

        if (!orderId) { return }
        const fetchOrder = await readOrderById(orderId)

        // Case : Suspect abnormal
        if (fetchOrder.result !== 'success' || !fetchOrder.info || !['pending', 'processing'].includes(fetchOrder.info.orderState)) {
            setAbnormal(true)
            await showNotice('Your order may not create successfully. Please contact support center.')
            navigate('/')
            return
        }

        const paymentMethodName = await getServiceName(fetchOrder.info.paymentMethod)
        const shippingMethodName = await getServiceName(fetchOrder.info.shippingMethod)

        const dataToSet = { ...fetchOrder.info, paymentMethod: paymentMethodName, shippingMethod: shippingMethodName }
        
        setOrder(dataToSet)
    }

    const getOrderAddress = async orderId => {

        if (!orderId) { return }

        const fetchAddresses = await readOrderAddressByOrderId(orderId)

        if (fetchAddresses.result !== 'success' || !fetchAddresses.info) {

            return
        }

        const shippingAddress = fetchAddresses.info.filter(address => address.type === 'shipping' && address.createdAt !== address.updatedAt)[0]
        const billingAddress = fetchAddresses.info.filter(address => address.type === 'billing' && address.createdAt !== address.updatedAt)[0]

        if (shippingAddress) {
            setShippingAddress(shippingAddress)
        }

        if (billingAddress) {
            setBillingAddress(billingAddress)
        }
    }

    const getOrderItem = async orderId => {
        const fetchItems = await readOrderItemByOrderId(orderId)

        if (fetchItems.result !== 'success' || !fetchItems.info?.length) {

        } else {
            setOrderItems(fetchItems.info)
        }
    }


    const ShippingAddress = () => {
        return (
            <div>
                <p style={{fontSize: '13px', color: '#666666'}}>Shipping Address</p>
                <div>
                    <span>{shippingAddress.firstName}</span>
                    <span>{shippingAddress.firstName && shippingAddress.lastName ? ' ' : null}</span>
                    <span>{shippingAddress.lastName}</span>
                    {shippingAddress.company ? <p>{shippingAddress.company}</p> : null}
                </div>
                <div>
                    <span>{shippingAddress.address1}</span>
                    <span>{shippingAddress.address2 && shippingAddress.address2 ? ', ' : null}</span>
                    <span>{shippingAddress.address2}</span>
                    <span>{shippingAddress.address3 ? ', ' : null}</span>
                    <span>{shippingAddress.address3}</span>
                </div>
                <div>
                    <span>{shippingAddress.city}</span>             
                    <span>{shippingAddress.city && shippingAddress.province ? ', ' : null}</span>
                    <span>{shippingAddress.province}</span>
                    <span>{shippingAddress.zip ? ', ' : null}</span>
                    <span>{shippingAddress.zip}</span>
                    <span>{shippingAddress.country ? ', ' : null}</span>
                    <span>{shippingAddress.country}</span>
                </div>
                <div style={{marginTop: '5px'}}>
                    <span>{shippingAddress.telephone1 ? 'Tel : ' : null}</span>
                    <span>{shippingAddress.telephone1}</span>
                    <span>{shippingAddress.telephone2 ? ', Tel2 : ' : null}</span>
                    <span>{shippingAddress.telephone2}</span>
                </div>
            </div>
        )
    }

    const BillingAddress = () => {
        return (
            <div>
                <p style={{fontSize: '13px', color: '#666666'}}>Billing Address</p>
                <div>
                    <span>{billingAddress.firstName}</span>
                    <span>{billingAddress.firstName && billingAddress.lastName ? ' ' : null}</span>
                    <span>{billingAddress.lastName}</span>
                    {billingAddress.company ? <p>{billingAddress.company}</p> : null}
                </div>
                <div>
                    <span>{billingAddress.address1}</span>
                    <span>{billingAddress.address2 && billingAddress.address2 ? ', ' : null}</span>
                    <span>{billingAddress.address2}</span>
                    <span>{billingAddress.address3 ? ', ' : null}</span>
                    <span>{billingAddress.address3}</span>
                </div>
                <div>
                    <span>{billingAddress.city}</span>             
                    <span>{billingAddress.city && billingAddress.province ? ', ' : null}</span>
                    <span>{billingAddress.province}</span>
                    <span>{billingAddress.zip ? ', ' : null}</span>
                    <span>{billingAddress.zip}</span>
                    <span>{billingAddress.country ? ', ' : null}</span>
                    <span>{billingAddress.country}</span>
                </div>
                <div style={{marginTop: '5px'}}>
                    <span>{billingAddress.telephone1 ? 'Tel : ' : null}</span>
                    <span>{billingAddress.telephone1}</span>
                    <span>{billingAddress.telephone2 ? ', Tel2 : ' : null}</span>
                    <span>{billingAddress.telephone2}</span>
                </div>
            </div>
        )
    }

    const ItemList = orderItems.map(item => {
        return (
            <div className='CheckoutItem'>
                <div className='ItemImage'>
                    <img src={getProductImageUrl(item.imageThumbnail)} alt='Mustom' />
                </div>
                <div className='ItemDetail'>
                    <p><span>{item.sku} - </span>{item.name}</p>
                    { item.optionName ? <p className='SmallText'>{item.optionName}</p> : null }
                    <span>{formatCurrency(item.appliedPrice)} </span>
                    <span>x {item.qty}</span>
                </div>
            </div>
        )
    })

    return (
        <div className='RegContainer' style={{minHeight: '80vh', filter: isAbnormal ? 'blur(5px)' : 'none'}}>
            <div id='OrderSuccessText'>
                <h1>Thanks for your order</h1>
                <h3>{`Your order #${order.orderNumber} has been successfully placed.`}</h3>
            </div>
            <div id='OrderInformationBlock'>
                <p className='OrderInformationTitle'>Order Information</p>
                <div className='OrderInformationHalf'>
                    <div>
                        <p style={{marginBottom: '10px', fontWeight: '500'}}>
                            Payment Method : {order.paymentMethod}
                        </p>
                        <p>Discount : {formatCurrency(order.discount)}</p>
                        <p>Shipping : {formatCurrency(order.shippingFee)}</p>
                    </div>
                    <div>
                        <p style={{marginBottom: '10px', fontWeight: '500'}}>
                            Shipping Method : {order.shippingMethod}
                        </p>
                        <p>Subtotal : {formatCurrency(order.itemTotal)}</p>
                        <p>Grand Total : {formatCurrency(order.grandTotal)}</p>
                    </div>
                </div>
                <p className='OrderInformationTitle' style={{marginTop: '50px'}}>Address</p>
                <div className='OrderInformationHalf'>
                    <ShippingAddress />
                    <BillingAddress />
                </div>
                <p className='OrderInformationTitle' style={{marginTop: '50px'}}>Item(s) Ordered</p>
                <div>
                    {ItemList}                     
                </div>
            </div>
            <div id='OrderSuccessButton'>
                <Link to={'/'} >
                    <span className='RegBtn'>Back to Main</span>
                </Link>
            </div>
        </div>        
    )
}


export default OrderResult

