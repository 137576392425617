// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { addItemToCart } from '../../../api/cart/cart'
import { readProductById } from '../../../api/product/product'
import { getProductImageUrl } from '../../../helper/getProductImageUrl'
import { formatCurrency } from '../../../helper/currencyFormatter'


const Item = props => {

    const [ product, setProduct ] = useState({})

    useEffect(() => {
        getProductInfo(props.productId)
    }, [])

    const getProductInfo = async productId => {
        const fetchProduct = await readProductById(productId)
        if (fetchProduct.result !== 'success' && !fetchProduct.info) {
            return
        }

        setProduct(fetchProduct.info)
    }

    
    return (
        <div style={{height: '140px', padding: '20px 0', borderBottom: '1px solid #E4E4E4'}}>
            <div className='ItemHorizontalContainer'> 
                <div className='ItemImage'>
                    <Link to={'/product/' + product.slug}>
                        <img src={getProductImageUrl(product.imageSmall)} alt='Product' />
                    </Link>
                </div>
                <div className='ItemName'>
                    <p>{product.sku}</p>
                    <Link to={'/product/' + product.slug}>
                        <h4>{product.name}</h4> 
                    </Link>
                </div>
                {/* <div className='ItemPrice'>
                    <p>{formatCurrency()}</p> 
                </div> */}
                <div className='ItemAction'>
                    <span className='RegSmallBtn' style={{marginBottom: '5px'}}>Add to Cart</span>
                    <span className='RegSmallBtnLight' onClick={() => props.removeItem()}>Remove</span>
                </div>
            </div>
        </div>
    )
}


export default Item


