// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import { useHistory } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'


const SuccessBlock = () => {

    const navigate = useNavigate()

    return (
        <React.Fragment>
            <div>
                <h3>Your account is now activated!</h3>
                <h3>Enjoy your shopping!</h3>
            </div>
            <div style={{marginTop: '100px'}}>
                <span className='FixedWidthBtn' onClick={() => navigate('/')}>Back to Main</span>
            </div>
        </React.Fragment>
    )
}


export default SuccessBlock








