// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { sendInformation } from '../../helper/crudHandler'


export const requestNewsletterSubscription = async email => {
    const subscriptionRequest = await sendInformation('/newsletter-subscription/subscribe', {
        email: email
    })

    return subscriptionRequest
}
