// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import FooterMenu from './FooterMenu'
import FooterCopy from './FooterCopy'
import './style.css'


const Footer = () => {
    return (
        <div id='FooterContainer'>
            <FooterMenu />
            <FooterCopy />
        </div>
    )
}


export default Footer