// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import { useParams } from 'react-router-dom'
import ProductSearch from './ProductSearch'
import PageSearch from './PageSearch'


const Search = props => {

    const { keyword } = useParams()

    return (
        <div className='RegContainer' style={{minHeight: '80vh'}}>
            <h1 className='PageTitle'>Search result for your keyword '{keyword}'</h1>
            <div className='RegGrayContainer'  style={{margin: '50px 0'}}>
                <ProductSearch />
                {/* <PageSearch /> */}
            </div>
        </div>        
    )
}


export default Search



