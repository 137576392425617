// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { createData } from '../../helper/crudHandler'


export const createBuyNowCart = async data => {
    return await createData('/cart/buy-now/add', data)
}
