// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { requestNewsletterSubscription } from '../../api/misc/newsletter'
import showNotice from '../../helper/noticeDialog'
import validateInput from '../../helper/inputValidator'
import linkedin from './linkedin.svg'
import facebook from './facebook.svg'
import twitter from './twitter.svg'
import youtube from './youtube.svg'
import mail from './mail.svg'


const FooterMenu = () => {

    const [ email, setEmail ] = useState('')

    const requestSubscription = async () => {

        let isValid = false

        validateInput(email, { type: 'email', maxLen: 50 }, callback => {
            if (!callback) {
                isValid = true
            } else {
                isValid = false
                setEmail('')
                showNotice(callback)
            }
        })

        if (!isValid) { return }

        const subscriptionRequest = await requestNewsletterSubscription(email)
        showNotice(subscriptionRequest.result !== 'success' ? 'Something went wrong! Please try again later.' : 'Welcome onboard!')
        setEmail('')
    }
    
    return (
        <React.Fragment>
            <div className='RegContainer' style={{height: '20px'}}>
                <div className='FooterMenuL'>
                    <p>HOW TO</p>
                    <Link to='/page/test'><span>How to Use</span></Link>
                    <span>Knowledge Base</span>
                    <Link to='/quickorder'><span>Quick Order</span></Link>
                </div>
                <div className='FooterMenuL'>
                    <p>SUPPORT</p>
                    <span>Help Center</span>
                    <span>FAQs</span>
                </div>
                <div className='FooterMenuL'>
                    <p>ABOUT</p>
                    <span>About Us</span>
                    <span>Contact</span>
                    <span>Blog</span>
                </div>
                <div className='FooterMenuR'>
                    <p>MUSTOM Demo Store</p>
                    <span style={{fontStyle: 'oblique'}}>Join our newsletter!</span>
                    <div id='Newsletter'>
                        <input
                            type='text'
                            name='newsletter'
                            id='newsletter'
                            value={email}
                            placeholder='Email Address'
                            onChange={event => setEmail(event.target.value)}
                        />
                        <span
                            onClick={requestSubscription}
                        >Submit</span>
                    </div>
                    <div id='FooterShare'>
                        <img src={linkedin} alt='LinkedIn' />
                        <img src={facebook} alt='FaceBook' />
                        <img src={twitter} alt='Twitter' />
                        <img src={youtube} alt='YouTube' />
                        <img src={mail} alt='eMail' />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default FooterMenu

