// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    createData,
    readData,
    readManyData,
    updateData
} from '../../helper/crudHandler'


export const createOrder = async data => {
    const createRequest = await createData('/order/create', data)
    
    if (createRequest.result !== 'success' || !createRequest.info) {
        localStorage.removeItem('cart_key')
    }

    return createRequest
}

export const createBuyNowOrder = async data => {
    return await createData('/order/buy-now/create', data)
}

export const readOrderByCustomerId = async customerId => {
    return await readData(`/order/customer-id/${customerId}`)
}

export const readOrderById = async orderId => {
    return await readData(`/order/id/${orderId}`)
}

export const readOrderItemByOrderId = async orderId => {
    return await readManyData(`/order-item/order-id/${orderId}`)
}

export const readOrderAddressByOrderId = async orderId => {
    return await readManyData(`/order-address/order-id/${orderId}`)
}

export const updateOrderById = async (orderId, data) => {
    return await updateData(`/order/id/${orderId}`, data)
}

export const updateOrderAddressByOrderId = async (orderId, data) => {
    return await updateData(`/order-address/order-id/${orderId}`, data)
}
