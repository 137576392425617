// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import LoginContext from '../../context/LoginContext'
import EmailInput from './EmailInput'
import ShippingAddress from './ShippingAddress'
import BillingAddress from './BillingAddress'
import ShippingMethod from './ShippingMethod'
import PaymentMethod from './PaymentMethod'
import OrderResult from './OrderResult'
import BrainTree from '../braintree/BrainTree'
import { finalizeCheckout } from '../../api/bankTransfer/bankTransfer'
import showNotice from '../../helper/noticeDialog'
import showInnerNotice from '../../helper/innerNoticeDialog'
import showAlert from '../../helper/alertDialog'
import {
    createOrder,
    createBuyNowOrder,
    readOrderById,
    readOrderItemByOrderId,
    readOrderAddressByOrderId
} from '../../api/order/order'
import { getConfig } from '../../helper/getConfig'
import { getProductImageUrl } from '../../helper/getProductImageUrl'
import { formatCurrency } from '../../helper/currencyFormatter'
import './style.css'


const Checkout = props => {

    const { key } = useParams()
    const location = useLocation()
    const user = useContext(LoginContext)
    const navigate = useNavigate()

    const [ email, setEmail ] = useState('')
    const [ shippingMethod, setShippingMethod ] = useState(null)
    const [ paymentMethod, setPaymentMethod ] = useState(null)
    const [ shippingAddress, setShippingAddress ] = useState(null)
    const [ billingAddress, setBillingAddress ] = useState(null)
    const [ orderId, setOrderId ] = useState('')
    const [ order, setOrder ] = useState({})
    const [ orderItems, setOrderItems ] = useState([])
    const [ isTermsConsented, toggleTerms ] = useState(false)
    const [ isPrivacyConsented, togglePrivacy ] = useState(false)
    const [ isShowDropIn, toggleDropIn ] = useState(false)
    const [ isShowResult, toggleResult ] = useState(false)


    useEffect(() => {
        generateOrder()
    }, [])

    const backToCart = async () => {
        await showNotice('Something went wrong!')
        navigate('/cart')
    }

    const generateOrder = async () => {

        // Prevent duplication
        if (orderId) { return }

        // Check if guest checkout is allowed
        const isGuestAllowed = getConfig('allow_guest_checkout') || 'disallow'

        if (!user.customerId && isGuestAllowed === 'disallow') {
            const alert = await showAlert(
                `Please sign in to proceed checkout. If you don't have an account please create one`,
                'Sign In/Up',
                'Continue Shopping'
            )

            navigate(alert === 'one' ? '/sign' : -1)
            return

        } else if (!user.customerId && isGuestAllowed === 'allow') {
            const alert = await showAlert(
                'Login in to your account and speed up the checkout.',
                'Sign In/Up',
                'Continue as a Guest'
            )

            if (alert === 'one') {
                navigate('/sign')
            }
        }

        let dataToSend
        const path = location.pathname

        // Check buy-now
        if (path.includes('buy-now')) {

            const createRequest = await createBuyNowOrder()

            if (createRequest.result !== 'success' || !createRequest.info) {
                navigate('/page-not-found')
            } else {
                setOrderId(createRequest.info)
                getOrderInfoSet(createRequest.info)
            }
        } else {

            const keyInStorage = localStorage.getItem('cart_key')
    
            if (keyInStorage !== key) { 
                navigate('/page-not-found')
            }

            dataToSend = { cartKey: keyInStorage }
            const createRequest = await createOrder(dataToSend)
    
            if (createRequest.result !== 'success' || !createRequest.info) {
                backToCart()
            } else {
                setOrderId(createRequest.info)
                getOrderInfoSet(createRequest.info)
            }
        }
    }

    const getOrderInfoSet = orderId => {
        getOrder(orderId)
        getOrderAddress(orderId)
        getOrderItem(orderId)
    }

    const getOrder = async orderId => {

        if (!orderId) { return }

        const fetchOrder = await readOrderById(orderId)
        if (fetchOrder.result !== 'success' || !fetchOrder.info) {
            backToCart()
            return
        } 
        
        setOrder(fetchOrder.info)

        if (fetchOrder.info.email) {
            setEmail(fetchOrder.info.email)
        }

        if (fetchOrder.info.shippingMethod) {
            setShippingMethod(fetchOrder.info.shippingMethod)
        }

        if (fetchOrder.info.paymentMethod) {
            setPaymentMethod(fetchOrder.info.paymentMethod)
        }

    }

    const getOrderAddress = async orderId => {

        if (!orderId) { return }

        const fetchAddresses = await readOrderAddressByOrderId(orderId)

        if (fetchAddresses.result !== 'success' || !fetchAddresses.info) {
            backToCart()
            return
        }

        const shippingAddress = fetchAddresses.info.filter(address => address.type === 'shipping' && address.createdAt !== address.updatedAt)[0]
        const billingAddress = fetchAddresses.info.filter(address => address.type === 'billing' && address.createdAt !== address.updatedAt)[0]

        if (shippingAddress) {
            setShippingAddress(shippingAddress)
        }

        if (billingAddress) {
            setBillingAddress(billingAddress)
        }
    }

    const getOrderItem = async orderId => {
        const fetchItems = await readOrderItemByOrderId(orderId)

        if (fetchItems.result !== 'success' || !fetchItems.info?.length) {
            backToCart()
        } else {
            setOrderItems(fetchItems.info)
        }
    }

    const successHandler = result => {

        if (paymentMethod === 'mustomBraintree' && !result) {
            showInnerNotice('Something went wrong. Please try again later.')
            toggleDropIn(false)
            return
        }

        toggleDropIn(false)
        toggleResult(true)
    }

    const proceedPayment = async () => {
        switch (true) {
            case !email:
                showNotice('Please enter email address!')
                return
            case !shippingMethod:
                showNotice('Please choose shipping method!')
                return
            case !paymentMethod:
                showNotice('Please choose payment method!')
                return
            case !shippingAddress:
                showNotice('Please enter shipping address!')
                return
            case !billingAddress:
                showNotice('Please enter billing address!')
                return
            case !isTermsConsented:
                showNotice('Please accept the Terms of Service!')
                return
            case !isPrivacyConsented:
                showNotice('Please accept the Privacy Policy!')
                return
            default:
                break
        }

        if (paymentMethod === 'mustomBraintree') {
            toggleDropIn(true)
            return
        }

        if (paymentMethod === 'mustomBankTransfer') {
            const finalizeRequest = await finalizeCheckout(orderId)
            if (finalizeRequest.result !== 'success') {
                showNotice('Failed to create order. Please try again later.')
            } else {
                successHandler()
            }
        }
    }

    const ItemList = orderItems.map((item, index) => {
        return (
            <div className='CheckoutItem'>
                <div className='ItemImage'>
                    <img src={getProductImageUrl(item.imageThumbnail)} alt='Mustom' />
                </div>
                <div className='ItemDetail'>
                    <span
                        style={{fontSize: '12px', color: '#666666', lineHeight: '12px', verticalAlign: 'top'}}
                    >
                    {item.sku}{item.optionName ? ` (${item.optionName})` : null }
                    </span>
                    <p style={{lineHeight: '5px'}}>{item.name}</p>
                    <p style={{marginTop: '8px', fontSize: '12px'}}>{formatCurrency(item.appliedPrice)} x {item.qty}</p>
                </div>
            </div>
        )
    })

    return (
        <React.Fragment>
            <div style={{display: isShowResult ? 'none' : 'block'}}>
                <h1 className='PageTitle'>Checkout</h1>  
                <div className='RegGrayContainer' style={{marginBottom: '100px'}}>
                    <div id='CheckoutLeft'>
                        <EmailInput
                            orderId={orderId}
                            customerId={user.customerId}
                            email={email}
                            updateCallback={() => getOrderInfoSet(orderId)}
                        />
                        <ShippingAddress
                            orderId={orderId}
                            customerId={user.customerId}
                            shippingAddress={shippingAddress}
                            updateCallback={() => getOrderInfoSet(orderId)}
                        />
                        <ShippingMethod
                            orderId={orderId}
                            shippingMethod={shippingMethod}
                            updateCallback={() => getOrderInfoSet(orderId)}
                        />
                        <PaymentMethod
                            orderId={orderId}
                            paymentMethod={paymentMethod}
                            updateCallback={() => getOrderInfoSet(orderId)}
                        />
                        <BillingAddress
                            orderId={orderId}
                            customerId={user.customerId}
                            billingAddress={billingAddress}
                            shippingAddress={shippingAddress}
                            updateCallback={() => getOrderInfoSet(orderId)}
                        />
                    </div>
                    <div id='CheckoutRight'>
                        <div id='CheckoutSummary'>
                            <h4 style={{textAlign: 'center'}}>Summary</h4>
                            <div className='CheckoutItemBlock'>
                                {ItemList}                     
                            </div>
                            <div id='CheckoutTotal'>
                                <div className='HalfContainer HalfLeft'>
                                    <span>Subtotal</span>
                                </div>
                                <div className='HalfContainer HalfRight'>
                                    <span>{formatCurrency(order.itemTotal)}</span>
                                </div>
                                <div className='HalfContainer HalfLeft'>
                                    <span>Discount</span>
                                </div>
                                <div className='HalfContainer HalfRight'>
                                    <span>{formatCurrency(order.conditionalDiscount)}</span>
                                </div>
                                <div className='HalfContainer HalfLeft'>
                                    <span>Shipping</span>
                                </div>
                                <div className='HalfContainer HalfRight'>
                                    <span>{formatCurrency(order.shippingFeeWithTax)}</span>
                                </div>
                                <div className='HalfContainer HalfLeft'>
                                    <span>Grand Total</span>
                                </div>
                                <div className='HalfContainer HalfRight'>
                                    <span>{formatCurrency(order.grandTotal)}</span>
                                </div>
                            </div>
                            <div id='CheckoutConsent'>
                                <label>
                                    <input
                                        type='checkbox'
                                        style={{marginRight: '10px', verticalAlign: 'middle'}}
                                        name='isTermsConsented'
                                        id='isTermsConsented'
                                        value={isTermsConsented}
                                        checked={isTermsConsented ? true : false}
                                        onChange={() => toggleTerms(isTermsConsented ? false : true)}
                                    />
                                    I've read and accept the Terms of Service.
                                </label>
                                <br />
                                <label>
                                    <input
                                        type='checkbox'
                                        style={{marginRight: '10px', verticalAlign: 'middle'}}
                                        name='isPrivacyConsented'
                                        id='isPrivacyConsented'
                                        value={isPrivacyConsented}
                                        checked={isPrivacyConsented ? true : false}
                                        onChange={() => togglePrivacy(isPrivacyConsented ? false : true)}
                                    />
                                    I've read and accept the Privacy Policy.
                                </label>               
                                <div style={{marginTop: '20px'}}>
                                    <span
                                        className='FullWidthBtn'
                                        onClick={proceedPayment}    
                                    >Place Your Order</span>
                                </div>
                                <p id='CheckoutLeave' onClick={() => navigate(-1)}>Continue Shopping</p>
                            </div>
                        </div>
                    </div>
                    { isShowDropIn ? <BrainTree orderId={orderId} customerId={user.customerId} paymentCallback={result => successHandler(result)} /> : null }
                </div>
            </div>
            { isShowResult ? <OrderResult orderId={orderId} />: null }
        </React.Fragment>
    )
}


export default Checkout