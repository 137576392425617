// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { createReview } from '../../../api/review/review'
import { getConfig } from '../../../helper/getConfig'
import showNotice from '../../../helper/noticeDialog'


const PostReview = props => {

    const initialData = {
        rate: 0,
        customerName: '',
        title: '',
        review: ''
    }

    const [ review, setReview ] = useState(initialData)
    const [ star, setStar ] = useState(0)
    const [ allowGuestPost, setGuestPost ] = useState('disallow')
   
    useEffect(() => {
        checkGuestPostAllowed()
    }, [])

    const checkGuestPostAllowed = () => {
        const isGuestAllowed = getConfig('review_allow_guest') || 'disallow'
        setGuestPost(isGuestAllowed)
    }

    const postReview = async () => {

        if (!props.customerId && allowGuestPost !== 'allow') {
            showNotice('Sorry, only registered customer can submit a review. If you have one, please sign in!')
            return
        }

        const dataToSend = {
            productId: props.productId,
            customerId: props.customerId || null,
            customerName: review.customerName,
            rate: review.rate,
            title: review.title,
            review: review.review
        }

        const createRequest = await createReview(dataToSend)

        if (createRequest.result === 'success') {
            showNotice('Your review has been sent!')
        } else {
            showNotice('Something went wrong! Please try again later')
        }
    }

    const onChangeHandler = async event => {
        if (!props.customerId && allowGuestPost !== 'allow') {
            await showNotice('Sorry, only registered customer can submit a review. If you have one, please sign in!')
            setReview(prevState => {
                return { ...prevState, [event.target.name]: '' }
            })
            return
        }

        setReview(prevState => {
            return { ...prevState, [event.target.name]: event.target.value }
        })
    }

    const setRate = rate => {
        setReview(prevState => {
            return { ...prevState, rate: rate }
        })
    }

    const Rate = () => {
        return (
            <div id='SetRateContainer'>
                <span
                    className={star >= 1 ? 'StarFilled' : 'StarEmpty'}
                    onClick={() => setRate(1)}
                    onMouseEnter={() => setStar(1)}
                    onMouseLeave={() => setStar(review.rate)}
                >&#128970;
                </span>
                <span
                    className={star >= 2 ? 'StarFilled' : 'StarEmpty'}
                    onClick={() => setRate(2)}
                    onMouseEnter={() => setStar(2)}
                    onMouseLeave={() => setStar(review.rate)}
                >&#128970;
                </span>
                <span
                    className={star >= 3 ? 'StarFilled' : 'StarEmpty'}
                    onClick={() => setRate(3)}
                    onMouseEnter={() => setStar(3)}
                    onMouseLeave={() => setStar(review.rate)}
                >&#128970;
                </span>
                <span
                    className={star >= 4 ? 'StarFilled' : 'StarEmpty'}
                    onClick={() => setRate(4)}
                    onMouseEnter={() => setStar(4)}
                    onMouseLeave={() => setStar(review.rate)}
                >&#128970;
                </span>
                <span
                    className={star >= 5 ? 'StarFilled' : 'StarEmpty'}
                    onClick={() => setRate(5)}
                    onMouseEnter={() => setStar(5)}
                    onMouseLeave={() => setStar(review.rate)}
                >&#128970;
                </span>
                <span>{review.rate} / 5</span>
            </div>
        )
    }

    return (
        <div id='ReviewLeft'>
            <h4>Post Your Review</h4>
            <div id='ReviewPost'>
                <Rate />
                <input
                    type='text'
                    name='title'
                    placeholder='Headline for your review'
                    value={review.title}
                    onChange={onChangeHandler}
                />
                <input
                    type='text'
                    name='customerName'
                    placeholder='Your name'
                    value={review.reviewTitle}
                    onChange={onChangeHandler}
                />
                <textarea
                    name='review'
                    value={review.reviewComment}
                    placeholder='Please leave your comment.'
                    onChange={onChangeHandler}
                />
                <span className='RegBtn' style={{width: '100%'}} onClick={postReview}>Submit</span>
            </div>
        </div>
    )
}


export default PostReview
