// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import { useNavigate } from 'react-router-dom'


const DeadLink = props => {

    const navigate = useNavigate()

    return (
        <React.Fragment>
            <div>
                <h1 style={{fontSize: '100px', margin: '50px 0 100px 0'}}>Ooops!</h1>
                <h3>Your password reset link has expired!</h3>
            </div>
            <div style={{marginTop: '100px'}}>
                <span className='FixedWidthBtn' onClick={() => navigate('/')}>Back To Main</span>
            </div>
        </React.Fragment>
    )
}

export default DeadLink