// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { readProvinceByCountry } from '../../api/common/address'


const Province = props => {

    const [ provinces, setProvinces ] = useState([])

    useEffect(() => {
        getProvinces(props.country)
    }, [ props.country ])


    const getProvinces = async country => {

        if (!country) { return }

        const fetchProvinces = await readProvinceByCountry(country)

        if (fetchProvinces.result === 'success' && Array.isArray(fetchProvinces.info)) {
            setProvinces(fetchProvinces.info)
        } else {
            setProvinces([])
        }
    }

    const inputHandler = event => {
        const province = event.target.value
        props.action(null, province)
    }

    const selectHandler = event => {
        const provinceCode = event.target.value
        const provinceName = event.target.options[event.target.selectedIndex].text
        props.action(provinceCode, provinceName)
    }

    const Option = provinces.map(province => {
        return (
            <option value={province.code} selected={props.selected?.code?.includes(province.code) ? true : false} >
                {province.name}
            </option>
        )
    })

    return (
        <React.Fragment>
            <input
                type='text'
                id={props.id}
                value={props.selected.name}
                placeholder='Province/State'
                onChange={inputHandler}
                style={{display: provinces.length ? 'none' : 'block'}}
            />
            <select
                name={props.id}
                id={props.id}
                onChange={selectHandler}
                style={{display: provinces.length ? 'block' : 'none'}}
            >
                <option value='' hidden>Please Select Province/State</option>
                { Option }
            </select>

        </React.Fragment>
    )
}


export default Province



