// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import ItemBox from '../partials/ItemBox'


const HomeHot = () => {
   
    return (
        
        <div className='RegContainer'>

            <h1 className='PageTitle'>Recommended Products</h1>
            <p className='SubtitleTxt'>Quisque a est in mauris ipsum ornare aliquet. Sed pretium pulvinar aliquet vehicula dolor at lacinia.<br />
                Tempor quam porta vitae. Donec mauris mauris, suscipit et lectus eget, aliquet pretium felis.</p>
{/* 
                <ItemBox /><ItemBox /><ItemBox /><ItemBox />
                <ItemBox /><ItemBox /><ItemBox /><ItemBox /> */}
        

        </div>
    )
}


export default HomeHot


