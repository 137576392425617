// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import CategoryBanner from './CategoryBanner'
import CategoryProduct from './CategoryProduct'
import './style.css'


const Category = props => {
    return (
        <div style={{position: 'relative'}}>
            <CategoryBanner />
            <CategoryProduct />
        </div>        
    )
}


export default Category




