// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ItemBox from '../partials/ItemBox'
import { readProductsByCategorySlug } from '../../api/category/category'


const CategoryProduct = props => {

    const [ products, setProduct ] = useState([])
    const { slug } = useParams()

    useEffect(() => {
        getItem(slug)
    }, [ slug ])

    
    const getItem = async slug => {

        if (!slug) { return }

        const queryOption = {
            // limit: 10,
            // offset: 0,
        }

        const fetchProducts = await readProductsByCategorySlug(slug, queryOption)

        if (fetchProducts.result !== 'success' || !fetchProducts.info) {
            return
        }

        setProduct(fetchProducts.info)
    }


    const ItemBoxes = products.map(item => {
        return (
            <ItemBox
                slug={item.slug}
                name={item.name}
                image={item.imageSmall}
                price={item.regularPrice}
            />
        )
    })

    const NoItem = () => {
        return (
            <p style={{
                textAlign: 'center',
                lineHeight: '500px'
            }}>Sorry! No Items found.</p>
        )
    }

    return (
        <React.Fragment>
            <div className='CategoryTop'>         
                <div className='RegContainer'>
                    <div id='CategoryTopL'>
                        <span>FILETER : lll</span>
                    </div>
                    <div id='CategoryTopR'>
                        <i className='material-icons'>view_stream</i>
                        <i className='material-icons'>view_module</i>
                    </div>
                </div>
            </div>
            <div className='RegContainer' style={{minHeight: '500px'}}>
                { products.length ? ItemBoxes : <NoItem /> }
            </div>
        </React.Fragment>
    )
}


export default CategoryProduct