// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


const showAlert = (message, buttonOneName = 'OK', buttonTwoName = 'Cancel') => {

    return new Promise(resolve => {

        const body = document.querySelector('body')
        const oldWrapper = document.querySelector('.NightWrapper')
        let background = oldWrapper ? oldWrapper : document.createElement('div')

        // Check if same alert is displayed
        let isDuplicated
        const existingMessage = document.querySelectorAll('.RegNoticeBox .message')
    
        for (const dialog of existingMessage) {
            if (dialog.innerText === message) {
                isDuplicated = true
            }
        }

        if (isDuplicated) { return }

        // Create Elements
        const box = document.createElement('div')
        const text = document.createElement('span')
        const buttonContainer = document.createElement('div')
        const buttonOne = document.createElement('span')
        const buttonTwo = document.createElement('span')

        // Set parent-child relation
        body.appendChild(background)
        background.appendChild(box)
        box.appendChild(text)
        box.appendChild(buttonContainer)
        buttonContainer.appendChild(buttonOne)
        buttonContainer.appendChild(buttonTwo)

        // Add class
        background.className = 'NightWrapper'
        box.className = 'RegNoticeBox'
        text.className = 'message'
        buttonContainer.className = 'RegNoticeBottomTwoButton'
        buttonOne.className = 'RegSmallBtn'
        buttonTwo.className = 'RegSmallBtnLight'

        // Add eventlistener
        buttonOne.addEventListener('click', buttonEventOne)
        buttonTwo.addEventListener('click', buttonEventTwo)

        // Put Text
        text.innerText = message
        buttonOne.innerText = buttonOneName
        buttonTwo.innerText = buttonTwoName

        // Left Button Event
        function buttonEventOne() {
            resolve('one')
            if(oldWrapper) {
                oldWrapper.removeChild(box)
            } else {
                body.removeChild(background)
            }
        }

        // Right Button Event
        function buttonEventTwo() {
            resolve('two')
            if(oldWrapper) {
                oldWrapper.removeChild(box)
            } else {
                body.removeChild(background)
            }
        }
    })
}

  
export default showAlert