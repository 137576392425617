// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { readManyData } from '../../helper/crudHandler'


// Get allowed country data
export const readCountryAllowed = async () => {
    return await readManyData('/common-data/allow-country')
}

// Get province data by country
export const readProvinceByCountry = async countryCode => {
    return await readManyData(`/common-data/province/country-code/${countryCode}`)
}