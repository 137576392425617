// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { sendInformation } from '../../helper/crudHandler'


export const validateKey = async data => {
    return await sendInformation('/auth/customer/email-confirmation', data)
}


export const resendConfirmationEmail = async data => {
    return await sendInformation('/auth/customer/resend', data)
}



