// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import HeaderLeft from './block/HeaderLeft'
import HeaderRight from './block/HeaderRight'
import LogoBlack from './logo_black.svg'


const HeaderScroll = props => {
    return (
        <React.Fragment>
            <div className={props.isOpen ? 'HeaderScroll isOpen' : 'HeaderScroll'}>
                <HeaderLeft />
                <div className='LogoContainer'>
                    <img src={LogoBlack} alt='mustom' />
                </div>
                <HeaderRight />
                <div id='HeaderBar'>
                    <div id='HeaderFill' style={{width: props.barWidth}}></div>               
                </div>
            </div>
        </React.Fragment>        
    )
}


export default HeaderScroll