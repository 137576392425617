// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'


const AddressBoxEdit = props => {

    const [ address, setAddress ] = useState(props.data)

    const onChangeHandler = event => {
        setAddress(prevState => {
            return { ...prevState, [event.target.name]: event.target.value }
        })
    }

    const closehander = value => {

        // 밸리데이션 로직 넣어야 함.

        if (!value) {
            props.closeMe(value)
        } else {
            props.closeMe(address)
        }
    }

    const boxStyle = {
        position: 'fixed',
        width: '700px',
        minHeight: '600px',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.7)',
        padding: '80px',
        background: '#FFFFFF'
    }

    const spanStyle = {
        display: 'inline-block',
        width: '30%',
        padding: '0 16px',
        textAlign: 'left'
    }

    const inputStyle = {
        display: 'inline-block',
        width: '70%',
        height: '30px',
        padding: '0 16px',
        margin: '5px 0',
        textAlign: 'left',
        border: 'none',
        background: '#F8F8F8',
        outline: 'none',
        resize: 'none'
    }

    return (             
        <div className='NightWrapper'>
            <div style={boxStyle}>
                <span style={spanStyle}>First Name</span>
                <input type='text' name='firstName' id='firstName' style={inputStyle} value={address.firstName} onChange={onChangeHandler} />
                <span style={spanStyle}>Last Name</span>
                <input type='text' name='lastName' id='lastName' style={inputStyle} value={address.lastName} onChange={onChangeHandler} />
                <span style={spanStyle}>Telephone 1</span>
                <input type='text' name='telephone_1' id='telephone_1' style={inputStyle} value={address.telephone_1} onChange={onChangeHandler} />
                <span style={spanStyle}>Telephone 2</span>
                <input type='text' name='telephone_2' id='telephone_2' style={inputStyle} value={address.telephone_2} onChange={onChangeHandler} />
                <span style={spanStyle}>Street Address 1</span>
                <input type='text' name='street_address1' id='street_address1' style={inputStyle} value={address.street_address1} onChange={onChangeHandler} />
                <span style={spanStyle}>Street Address 2</span>
                <input type='text' name='street_address2' id='street_address2' style={inputStyle} value={address.street_address2} onChange={onChangeHandler} />
                <span style={spanStyle}>City</span>
                <input type='text' name='city' id='city' style={inputStyle} value={address.city} onChange={onChangeHandler} />
                <span style={spanStyle}>States/Province</span>
                <input type='text' name='province' id='province' style={inputStyle} value={address.province} onChange={onChangeHandler} />
                <span style={spanStyle}>ZIP</span>
                <input type='text' name='zip' id='zip' style={inputStyle} value={address.zip} onChange={onChangeHandler} />
                <span style={spanStyle}>Country</span>
                <input type='text' name='country' id='country' style={inputStyle} value={address.country} onChange={onChangeHandler} />
                <input type='checkbox' name='isDefaultBillTo' style={{verticalAlign: 'middle'}} value={address.subscription} onChange={onChangeHandler} />
                <span style={{paddingLeft: '10px', verticalAlign: 'middle'}}>Set it as Default Billing Address</span>
                <input type='checkbox' name='isDefaultShipTo' style={{verticalAlign: 'middle', marginLeft: '10px'}} value={address.subscription} onChange={onChangeHandler} />
                <span style={{paddingLeft: '10px', verticalAlign: 'middle'}}>Set it as Default Shipping Address</span>
                <div className='RegNoticeBoxBottom'>
                    <span className='RegSmallBtn' onClick={() => closehander(true)}>Save</span>
                    <span className='RegSmallBtn' onClick={() => closehander(false)}>Cancel</span>
                </div>
            </div>
        </div>
    )
}


export default AddressBoxEdit
