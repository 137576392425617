// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState } from 'react'
import { sendResetRequest } from '../../api/auth/resetPassword'
import showNotice from '../../helper/noticeDialog'


const ForgetPassword = props => {

    const [ email, setEmail ] = useState('')

    const sendEmail = async () => {
        if(!email) {
            showNotice('Please enter email address.')
        }

        const resetRequest = await sendResetRequest({ userName: email })

        showNotice(resetRequest.result !== 'success' ?
            'Something went wrong. Please try again later.' :
            `You will receive an email to reset your password, if your email is not wrong. Please check your inbox.`
        )

        setEmail('')
    }


    return (
        <React.Fragment>
            <div className='HalfContainer'>
                <div className='SignLeft'>
                    <p>Please enter your username. You will receive an email message with introductions on how to reset your password.
                    Please note that this email only valid for ONE hour.</p>
                    <input
                        name='email'
                        type='email'
                        placeholder = 'e.g. john-doe@domain.com'
                        style={{marginTop: '50px'}}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                    <span className='FullWidthBtn' style={{margin : '20px 0'}} onClick={sendEmail}>Get New Password</span>
                    <p style={{textAlign: 'right', cursor: 'pointer'}} onClick={() => props.changePage('sign-in')}>Back to Sign-In</p>
                </div>
            </div>
            <div className='HalfContainer' style={{borderLeft: '1px solid #E4E4E4'}}>
                <div className='SignRight'>
                    <h5 style={{marginBottom: '20px'}}>Trouble with Sign In?</h5>
                    <p style={{marginBottom: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam, quam at eleifend eleifend, tellus ex faucibus elit, quis pharetra sapien purus eu ex. Pellentesque rhoncus molestie elit ut elementum.</p>
                    <p style={{marginBottom: '20px'}}>Nulla semper malesuada urna, ac imperdiet leo tincidunt sed. Aliquam vulputate neque at dui ullamcorper, sit amet tristique purus volutpat.</p>
                    <p style={{marginBottom: '30px'}}>Quisque gravida elit a congue malesuada. Ut malesuada convallis turpis nec auctor. Pellentesque molestie magna lectus, interdum faucibus eros egestas et. In enim massa, imperdiet pharetra nunc varius, posuere rutrum urna.</p>                    
                </div>
            </div>
        </React.Fragment>
    )
}


export default ForgetPassword












