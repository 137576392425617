// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { sendInformation } from '../../helper/crudHandler'


export const requestAccountClousure = async data => {
    return await sendInformation('/customer/close-account', data)
}

export const validateClosureKey = async data => {
    return await sendInformation('/auth/customer/close-account-confirmation', data)
}