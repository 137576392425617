// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import { getConfig } from './getConfig'


export const formatCurrency = num => {
    const precision = getConfig('currency_precision_admin') || 2
    const symbol = getConfig('currency_symbol_admin') || '$'
    const symbolPosition = getConfig('currency_symbol_position_admin') || 'left'
    const numberFormat = getConfig('currency_number_format_admin') || 'en-US'
    const decimalCalculation = getConfig('currency_decimal_calculation_admin') || 'round'

    if (!num) {
        const result = symbolPosition === 'left' ? `${symbol} -` : `- ${symbol}` 
        return result
    }

    const number = num
    const precisionConvert = precision === 0 ? 0 : `1e${precision}`

    let calculated
    
    switch (decimalCalculation) {
        case 'round':
            calculated = (Math.round(number * precisionConvert) / precisionConvert)
            break

        case 'round-up':
            calculated = (Math.ceil(number * precisionConvert) / precisionConvert)
            break

        case 'round-down':
            calculated = (Math.floor(number * precisionConvert) / precisionConvert)
            break

        default:
            calculated = (Math.round(number * precisionConvert) / precisionConvert)
            break
    }   

    const currencyFormat = new Intl.NumberFormat(numberFormat, {
            maximumFractionDigits: precision,
            minimumFractionDigits: precision
        }).format(calculated)
    

    if (symbolPosition === 'right') {
        return `${currencyFormat} ${symbol}`
    } else {
        return `${symbol} ${currencyFormat}`
    }

}