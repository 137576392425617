// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    createData,
    readManyData,
    updateData,
    deleteData
} from '../../helper/crudHandler'


export const readReviewByProuctId = async productId => {
    return await readManyData(`/review/product-id/${productId}`)
}

export const readReviewByCustomerId = async customerId => {
    return await readManyData(`/review/customer-id/${customerId}`)
}

export const createReview = async data => {
    return await createData('/review', data)
}

export const updateReview = async (reviewId, data) =>  {
    return await updateData(`/review/id/${reviewId}`)
}

export const deleteReviewById = async reviewId => {
    return await deleteData(`/review/id/${reviewId}`)
}
