// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import {
    createData,
    readData,
    updateData,
    deleteData
} from '../../helper/crudHandler'


export const readCustomerById = async customerId => {
    return await readData(`/customer/id/${customerId}`)
}

export const reateCustomer = async data => {
    return await createData('/customer', data)
}

export const updateCustomer = async (customerId, data) => {
    return await updateData(`/customer/id/${customerId}`, data)
}

export const deleteCustomer = async customerId => {
    return await deleteData(`/customer/id/${customerId}`)
}


