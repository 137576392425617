// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React from 'react'
import { updateCartItem, deleteCartItem } from '../../api/cart/cart'
import showNotice from '../../helper/noticeDialog'
import { getProductImageUrl } from '../../helper/getProductImageUrl'
import { formatCurrency } from '../../helper/currencyFormatter'


const CartItem = props => {

    const changeQty = event => {
        const targetClass = event.target.className
        const value = event.target.value

        if (targetClass === 'material-icons minus' && props.itemData.qty > 1 ) {
            updateQty(props.itemData.qty - 1)
        } 

        if (targetClass === 'material-icons plus') {
            updateQty(props.itemData.qty + 1)
        }

        if (targetClass === 'inputBox' && value && value % 1 === 0) {
            updateQty(value)
        }
        
        return
    }


    const updateQty = async qty => {
        const updateRequest = await updateCartItem(props.itemData.id, qty)

        if (updateRequest.result !== 'success') {
            showNotice('Something went wrong!')
        }

        props.updateCallback()
    }


    const removeItem = async () => {

        const deleteRequest = await deleteCartItem(props.itemData.id)
        
        if (deleteRequest.result !== 'success') {
            showNotice('Something went wrong!')
        }
        
        props.updateCallback()
    }
    
    return (
        <React.Fragment>
            <tr style={{height: '100px'}}>
                <td>
                    <img src={getProductImageUrl(props.itemData.thumbnail)} alt='Mustom' style={{width: '50px', height: '50px'}}/>
                </td>
                <td>
                    <p style={{size : '0.5'}}>Item# : {props.itemData.sku}</p>
                    <p>{props.itemData.name}</p>
                    {props.itemData.option ? <p>{props.itemData.option}</p> : null }
                </td>
                <td>
                    <span className='currency'>{formatCurrency(props.itemData.unitPrice)}</span>
                </td>
                <td>
                    <div id='CartAddBox'>
                        <span className='material-icons minus' onClick={changeQty}>remove</span>
                            <input type='text' className='inputBox' value={props.itemData.qty} onChange={changeQty} />
                        <span className='material-icons plus' onClick={changeQty}>add</span>
                    </div>
                </td>
                <td>
                    <span className='currency'>{formatCurrency(props.itemData.subtotal)}</span>
                </td>
                <td>
                    <span className='material-icons' style={{color: '#CCCCCC', verticalAlign: 'middle', cursor: 'pointer'}} onClick={removeItem}>delete</span>
                </td>
            </tr>
        </React.Fragment>
    )
}


export default CartItem
    


