// MUSTOM, More Than Custom, https://mustom.com
// Copyright © Ryu Woosik. All rights reserved.


import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'


const Dashboard = props => {

    return (
        <div className='AccountContent'>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi urna neque, laoreet eget finibus id</p>
            <p>Rharetra in ligula. Aenean dictum suscipit lacinia. Curabitur pellentesque feugiat interdum.</p>
            <br />
            <p>Integer molestie enim nunc, sed rutrum leo auctor vitae. Vestibulum ante ipsum primis in faucibus orci luctus et</p>
            <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut pretium non augue eget congue.</p>
            <br />
            <p>Proin ut leo faucibus, ornare augue placerat, pulvinar. Fusce sapien quam, finibus laoreet tellus interdum et.</p>
            <p>Aliquam pharetra tincidunt auctor.</p>



        </div>
    
    )
}


export default Dashboard



